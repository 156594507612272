import React from "react";
import { WithAPICall } from "../utils/apiUtil";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TextField,
  LinearProgress,
  TableRow,
  Alert,
  AlertTitle,
  Paper,
  Button,
  Select,
  Typography,
  IconButton,
  Chip,
  MenuItem,
  Snackbar,
  Grid,
  TableSortLabel,
} from "@mui/material";
import _, { debounce } from "lodash";
import { QuickScore } from "quick-score";
import { Box, Stack } from "@mui/system";
import Iconify from "src/components/iconify";
import ProviderServiceCreate from "./ProviderServiceCreate";
import cogoToast from "cogo-toast";
import { NoResultView, View500 } from "src/sections/error";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import ListServices from "../Services/ListServices";

const ACTIVE_STATUS_OPTIONS = ["ACTIVE", "TEMP_CLOSED", "PERM_CLOSED"];
const COST_MODEL_OPTIONS = ["FLAT_RATE", "USAGE_BASED", "HYBRID"];

class ProviderServicesNew extends React.Component {
  state = {
    isLoading: true,
    isError: false,
    masterServices: [],
    providerServices: [],
    quickSearchTxt: null,
    orderBy: "serviceName",
    order: "asc",
  };

  componentDidMount() {
    this.setState({
      ...this.props.data,
    });
    this.fetchData();
  }

  fetchData = async () => {
    try {
      let res_ms = await this.props.apiCallPost(
        "/master-service/masterServiceGetAll",
        {}
      );

      res_ms = res_ms.filter((x) => x.serviceRequiresProviderType === "Clinic");
      res_ms = _.sortBy(res_ms, "serviceName");

      // TODO(bulk update)

      let res_ps = await this.props.apiCallPost(
        "/provider-service-link/providerServiceLinkGetAll",
        {
          doesApplyToNetwork: this.props.doesApplyToNetwork,
          [this.props.doesApplyToNetwork ? "networkId" : "providerId"]:
            this.props.id,
        }
      );

      res_ps = res_ps.filter((x) => x.masterService);

      for (let ms of res_ms) {
        const msId = ms._id;
        const ps = _.find(res_ps, (x) => x.masterService._id === msId);
        ms.providerServices = ps ? { ...ps, isDirty: false } : null;
      }

      const filteredList = new Set(res_ms.map((x) => x._id));

      this.setState({
        masterServices: res_ms,
        providerServices: res_ps,
        filteredList,
        isLoading: false,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Provider Services");
      this.setState({ isLoading: false, isError: true });
    }
  };

  addPSLink = async (row, index) => {
    try {
      let res = await this.props.apiCallPost(
        "/provider-service-link/createProviderServiceLinkFromName",
        {
          providerServiceAlias: row.serviceName,
          doesApplyToNetwork: this.props.doesApplyToNetwork,
          networkId: this.props.doesApplyToNetwork ? this.props.id : null,
          providerId: this.props.doesApplyToNetwork ? null : this.props.id,
          masterService: row._id,
        }
      );

      const masterServices = this.state.masterServices;
      for (let m of masterServices) {
        if (m._id === row._id) {
          m.providerServices = { ...res, isDirty: false };
        }
      }

      this.setState({
        isLoading: false,
        providerServices: [...this.state.providerServices, res],
        masterServices: [...masterServices],
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Adding Provider Service Link");
      this.setState({ isLoading: false, isError: true });
    }
  };

  savePSL = async (row, index) => {
    try {
      this.setState({ isSaving: true });
      let res = await this.props.apiCallPost(
        "/provider-service-link/providerServiceLinkUpdateManyScalarValues",
        {
          providerServiceLinkId: row.providerServices?._id,
          uops: [
            {
              key: "providerServiceAlias",
              value: row.providerServices?.providerServiceAlias,
            },
            { key: "activeStatus", value: row.providerServices?.activeStatus },
            { key: "costModel", value: row.providerServices?.costModel },
            { key: "baseCostUsd", value: row.providerServices?.baseCostUsd },
            { key: "unitCostUsd", value: row.providerServices?.unitCostUsd },
            {
              key: "costModelUnitOfWork",
              value: row.providerServices?.costModelUnitOfWork,
            },
          ],
        }
      );
      const masterServices = this.state.masterServices;
      // masterServices[index].providerServices = { ...res, isDirty: false };
      for (let m of masterServices) {
        if (m._id === row._id) {
          m.providerServices = { ...res, isDirty: false };
        }
      }

      this.setState({ masterServices, isSaving: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Saving Provider Service Link");
      this.setState({ isSaving: false, isError: true });
    }
  };

  updateScalarValue = async (key, value) => {
    console.log({ key, value });
    if (value === null || value === undefined || value == "") {
      return;
    }
    if (isNaN(parseFloat(value))) {
      cogoToast.error("Invalid value");
      return;
    }

    const apiRoute = this.props.doesApplyToNetwork
      ? "/provider/providerNetworkUpdateScalarValue"
      : "/provider/providerUpdateScalarValue";

    try {
      await this.props.apiCallPost(apiRoute, {
        [this.props.doesApplyToNetwork ? "networkId" : "providerId"]:
          this.props.id,
        key: key,
        value: parseFloat(value),
      });
    } catch (err) {
      cogoToast.error("Error Updating Provider Data");
      console.log(err);
    }
  };

  updateCollectionFee = (fee, type) => {
    this.setState({ [type]: fee });
  };

  renderTableRow = (row, index) => {
    if (!row.providerServices) {
      return (
        <TableRow key={index}>
          <TableCell
            style={{
              maxWidth: 150, // Adjust this value as needed
              whiteSpace: "normal",
              wordBreak: "break-word",
            }}
          >
            {row.serviceName}
          </TableCell>
          <TableCell colSpan={4}>
            <Button
              variant="contained"
              size="small"
              onClick={() => this.addPSLink(row, index)}
            >
              Click to enable this service for this provider.
            </Button>
          </TableCell>
        </TableRow>
      );
    }
    return (
      <TableRow key={index}>
        <TableCell
          style={{
            maxWidth: 150, // Adjust this value as needed
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          {row.serviceName}
        </TableCell>
        <TableCell>{row.providerServices?.providerServiceCode}</TableCell>
        <TableCell
          style={{
            maxWidth: 150, // Adjust this value as needed
            whiteSpace: "normal",
            wordBreak: "break-word",
          }}
        >
          <TextField
            key={`${row._id}__${row.updatedAt}_alias`}
            label="Provider Alias"
            value={row.providerServices?.providerServiceAlias}
            onChange={(e) => {
              let masterServices = _.cloneDeep(this.state.masterServices);
              // masterServices[index].providerServices.providerServiceAlias =
              //   e.target.value;
              // masterServices[index].providerServices.isDirty = true;
              for (let m of masterServices) {
                if (m._id === row._id) {
                  m.providerServices.providerServiceAlias = e.target.value;
                  m.providerServices.isDirty = true;
                }
              }
              this.setState({ masterServices });
            }}
          />
        </TableCell>
        <TableCell>{this.renderStatus(row, index)}</TableCell>
        <TableCell>
          {this.renderCostModel(row, index)}
          {/* {this.renderCostModelOptions(row, index)} */}
        </TableCell>
        <TableCell>
          <Button
            variant="contained"
            size="small"
            onClick={() => this.savePSL(row, index)}
            disabled={this.state.isSaving || !row.providerServices?.isDirty}
          >
            Save
          </Button>
        </TableCell>
      </TableRow>
    );
  };

  handleRequestSort = (property) => {
    const isAsc = this.state.orderBy === property && this.state.order === "asc";
    this.setState({
      order: isAsc ? "desc" : "asc",
      orderBy: property,
    });
  };

  getSorting = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => this.descendingComparator(a, b, orderBy)
      : (a, b) => -this.descendingComparator(a, b, orderBy);
  };

  descendingComparator = (a, b, orderBy) => {
    if (orderBy === "serviceName") {
      return b[orderBy].localeCompare(a[orderBy]);
    }
    if (
      orderBy === "providerServiceCode" ||
      orderBy === "providerServiceAlias" ||
      orderBy === "activeStatus"
    ) {
      return (b.providerServices?.[orderBy] || "").localeCompare(
        a.providerServices?.[orderBy] || ""
      );
    }
    return 0;
  };

  renderTableHead = () => {
    const { order, orderBy } = this.state;
    const headCells = [
      { id: "serviceName", label: "Service" },
      { id: "providerServiceCode", label: "Prov. Code" },
      { id: "providerServiceAlias", label: "Prov. Alias" },
      { id: "activeStatus", label: "Active Status" },
      { id: "rate", label: "Rate", sortable: false },
      { id: "action", label: "Action", sortable: false },
    ];

    return (
      <TableHead>
        <TableRow>
          {headCells.map((headCell) => (
            <TableCell key={headCell.id}>
              {headCell.sortable !== false ? (
                <TableSortLabel
                  active={orderBy === headCell.id}
                  direction={orderBy === headCell.id ? order : "asc"}
                  onClick={() => this.handleRequestSort(headCell.id)}
                >
                  {headCell.label}
                </TableSortLabel>
              ) : (
                headCell.label
              )}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  };

  render() {
    if (this.state.isError) {
      return <View500 />;
    }
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    return (
      <div className="p-4">
        {this.renderAlert()}
        <Typography variant="h6">Collection Fees</Typography>
        <TableContainer component={Paper} className="mb-4">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Service</TableCell>
                <TableCell>Fee</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>Blood Collection (Venipuncture) Fee</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.bloodCollectionFee}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "bloodCollectionFee"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "bloodCollectionFee",
                        this.state.bloodCollectionFee
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Urine Collection Fee</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.urineLabCollectionFee}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "urineLabCollectionFee"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "urineLabCollectionFee",
                        this.state.urineLabCollectionFee
                      )
                    }
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Urine Drug Collection Fee (DOT)</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.urineCollectionFeeDOT}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "urineCollectionFeeDOT"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "urineCollectionFeeDOT",
                        this.state.urineCollectionFeeDOT
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Urine Drug Collection Fee (Non-DOT)</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.urineCollectionFeeNonDOT}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "urineCollectionFeeNonDOT"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "urineCollectionFeeNonDOT",
                        this.state.urineCollectionFeeNonDOT
                      )
                    }
                  />
                </TableCell>
              </TableRow>

              <TableRow>
                <TableCell>Blood/Urine Collection Fee</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.bloodUrineCollectionFee}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "bloodUrineCollectionFee"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "bloodUrineCollectionFee",
                        this.state.bloodUrineCollectionFee
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Saliva Collection Fee</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.salivaCollectionFee}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "salivaCollectionFee"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "salivaCollectionFee",
                        this.state.salivaCollectionFee
                      )
                    }
                  />
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Hair Collection Fee</TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={this.state.hairCollectionFee}
                    onChange={(e) =>
                      this.updateCollectionFee(
                        e.target.value,
                        "hairCollectionFee"
                      )
                    }
                    onBlur={() =>
                      this.updateScalarValue(
                        "hairCollectionFee",
                        this.state.hairCollectionFee
                      )
                    }
                  />
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>

        <Typography variant="h6">Clinic Services</Typography>
        <Grid container spacing={2} className="mt-4 mb-4">
          <Grid item>
            <TextField
              margin="dense"
              id="name"
              name="name"
              label="Quick Search"
              disabled={this.state.isLoading}
              value={this.state.quickSearchTxt}
              onChange={(e) => {
                this.setState({ quickSearchTxt: e.target.value }, () => {
                  this.handleSearch(e.target.value);
                });
              }}
            />
          </Grid>
        </Grid>
        <TableContainer component={Paper} className="mb-4">
          <Table>
            {this.renderTableHead()}
            <TableBody>
              {this.state.masterServices
                .filter((row) => this.state.filteredList.has(row._id))
                .sort(this.getSorting(this.state.order, this.state.orderBy))
                .map((row, index) => this.renderTableRow(row, index))}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    );
  }
  handleSearch = debounce((s) => {
    if (s == "" || !s || s.length < 4) {
      const filteredList = new Set(this.state.masterServices.map((x) => x._id));
      this.setState({
        filteredList,
      });
      return;
    }

    let qs = new QuickScore(this.state.masterServices, ["serviceName"]);
    let res = qs.search(s);
    let it = new Set(res.map((each) => each.item._id));
    // console.log({ s, res });
    this.setState({
      filteredList: it,
    });
  }, 300);

  renderAlert = () => {
    return (
      <Alert severity="warning">
        <AlertTitle>Careful!</AlertTitle>
        Be thoughtful while changing data on this tab. It will have a cascading
        effect!
      </Alert>
    );
  };

  renderCostModelOptions = (row, i, loading) => {
    // if (!row.providerServices?.costModel) return null;
    let cm = row.providerServices?.costModel;
    switch (cm) {
      case "FLAT_RATE":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
          >
            <TextField
              size="small"
              disabled={this.state.isSaving}
              key={`${row._id}__${row.updatedAt}`}
              value={row.providerServices.baseCostUsd}
              onChange={(e) => {
                let masterServices = this.state.masterServices;
                for (let m of masterServices) {
                  if (m._id === row._id) {
                    m.providerServices.baseCostUsd = e.target.value;
                    m.providerServices.isDirty = true;
                  }
                }
                this.setState({ masterServices });
              }}
              label="Flat Rate (USD)"
            />
          </Box>
        );
      case "USAGE_BASED":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            <TextField
              size="small"
              disabled={this.state.isSaving}
              value={row.providerServices.unitCostUsd}
              onChange={(e) => {
                let masterServices = this.state.masterServices;
                let idx = i;
                // masterServices[idx].providerServices.unitCostUsd =
                //   e.target.value;
                // masterServices[idx].providerServices.isDirty = true;
                for (let m of masterServices) {
                  if (m._id === row._id) {
                    m.providerServices.unitCostUsd = e.target.value;
                    m.providerServices.isDirty = true;
                  }
                }
                this.setState({ masterServices });
              }}
              key={`${row._id}__${row.updatedAt}`}
              label="Unit Rate (USD)"
            />
            <TextField
              size="small"
              disabled={this.state.isSaving}
              key={`${row._id}__${row.updatedAt}`}
              value={row.providerServices.costModelUnitOfWork}
              onChange={(e) => {
                let masterServices = this.state.masterServices;
                let idx = i;
                // masterServices[idx].providerServices.costModelUnitOfWork =
                //   e.target.value;
                // masterServices[idx].providerServices.isDirty = true;
                for (let m of masterServices) {
                  if (m._id === row._id) {
                    m.providerServices.costModelUnitOfWork = e.target.value;
                    m.providerServices.isDirty = true;
                  }
                }
                this.setState({ masterServices });
              }}
              label="Unit"
            />
          </Box>
        );
      case "HYBRID":
        return (
          <Stack spacing={2}>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                size="small"
                disabled={this.state.isSaving}
                key={`${row._id}__${row.updatedAt}`}
                value={row.providerServices.baseCostUsd}
                onChange={(e) => {
                  let masterServices = this.state.masterServices;
                  let idx = i;
                  // masterServices[idx].providerServices.baseCostUsd =
                  //   e.target.value;
                  // masterServices[idx].providerServices.isDirty = true;
                  for (let m of masterServices) {
                    if (m._id === row._id) {
                      m.providerServices.baseCostUsd = e.target.value;
                      m.providerServices.isDirty = true;
                    }
                  }
                  this.setState({ masterServices });
                }}
                label="Flat Rate (USD)"
              />
            </Box>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <TextField
                size="small"
                disabled={this.state.isSaving}
                key={`${row._id}__${row.updatedAt}`}
                value={row.providerServices.unitCostUsd}
                onChange={(e) => {
                  let masterServices = this.state.masterServices;
                  let idx = i;
                  // masterServices[idx].providerServices.unitCostUsd =
                  //   e.target.value;
                  // masterServices[idx].providerServices.isDirty = true;
                  for (let m of masterServices) {
                    if (m._id === row._id) {
                      m.providerServices.unitCostUsd = e.target.value;
                      m.providerServices.isDirty = true;
                    }
                  }
                  this.setState({ masterServices });
                }}
                label="Unit Rate (USD)"
              />
              <TextField
                size="small"
                key={`${row._id}__${row.updatedAt}`}
                disabled={this.state.isSaving}
                value={row.providerServices.costModelUnitOfWork}
                onChange={(e) => {
                  let masterServices = this.state.masterServices;
                  let idx = i;
                  // masterServices[idx].providerServices.costModelUnitOfWork =
                  //   e.target.value;
                  // masterServices[idx].providerServices.isDirty = true;
                  for (let m of masterServices) {
                    if (m._id === row._id) {
                      m.providerServices.costModelUnitOfWork = e.target.value;
                      m.providerServices.isDirty = true;
                    }
                  }
                  this.setState({ masterServices });
                }}
                label="Unit"
              />
            </Box>
          </Stack>
        );
      default:
        return null;
    }
  };
  renderCostModel = (row, i) => {
    return (
      <Stack spacing={2}>
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <Select
            size="small"
            disabled={this.state.isSaving}
            value={row.providerServices?.costModel}
            onChange={(e) => {
              let masterServices = this.state.masterServices;
              let idx = i;
              console.log({ idx });
              // masterServices[idx].providerServices.costModel = e.target.value;
              // masterServices[idx].providerServices.costModelUnitOfWork = null;
              // masterServices[idx].providerServices.baseCostUsd = null;
              // masterServices[idx].providerServices.unitCostUsd = null;
              // masterServices[idx].providerServices.isDirty = true;
              for (let m of masterServices) {
                if (m._id === row._id) {
                  m.providerServices.costModel = e.target.value;
                  m.providerServices.costModelUnitOfWork = null;
                  m.providerServices.baseCostUsd = null;
                  m.providerServices.unitCostUsd = null;
                  m.providerServices.isDirty = true;
                }
              }
              this.setState({ masterServices });
            }}
            sx={{
              minWidth: "100px",
            }}
          >
            {COST_MODEL_OPTIONS.map((each) => {
              return <MenuItem value={each}>{each}</MenuItem>;
            })}
          </Select>
        </Box>
        {this.renderCostModelOptions(row, i)}
      </Stack>
    );
  };
  renderStatus = (row, i) => {
    return (
      <Box>
        <Select
          size="small"
          disabled={this.state.isSaving}
          value={row.providerServices?.activeStatus}
          onChange={(e) => {
            let masterServices = this.state.masterServices;
            // let idx = i;
            // masterServices[idx].providerServices.activeStatus = e.target.value;
            // masterServices[idx].providerServices.isDirty = true;
            for (let m of masterServices) {
              if (m._id === row._id) {
                m.providerServices.activeStatus = e.target.value;
                m.providerServices.isDirty = true;
              }
            }
            this.setState({ masterServices });
          }}
          sx={{
            minWidth: "100px",
          }}
        >
          {ACTIVE_STATUS_OPTIONS.map((each) => {
            return <MenuItem value={each}>{each}</MenuItem>;
          })}
        </Select>
      </Box>
    );
  };
}

export default WithAPICall(ProviderServicesNew);
