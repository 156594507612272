import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import _ from "lodash";
import { useProtectedApi } from "@/hooks/use-apiCall";

const FormContainer = styled("div")({
  width: "210mm",
  minHeight: "297mm",
  padding: "5mm",
  margin: "auto",
  fontFamily: "Arial, sans-serif",
  fontSize: "9pt",
  lineHeight: "1.1",
  backgroundColor: "#fff",
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "10mm",
});

const Title = styled("h3")({
  fontSize: "12pt",
  fontWeight: "bold",
  margin: "0",
});

const Table = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "4mm",
});

const Td = styled("td")({
  border: "1px solid black",
  padding: "1mm 2mm",
  height: "6mm",
  verticalAlign: "top",
});

const SectionTitle = styled("div")({
  fontWeight: "bold",
  marginBottom: "1mm",
  fontSize: "10pt",
});

const Checkbox = styled("input")({
  marginRight: "1mm",
});

const TextArea = styled("textarea")({
  width: "100%",
  height: "12mm",
  border: "1px solid black",
  fontSize: "9pt",
  padding: "1mm",
});

const SmallText = styled("div")({
  fontSize: "7pt",
  marginTop: "1mm",
});

const CompactDiv = styled("div")({
  marginBottom: "1mm",
});

const FormPreEmploymentForArkema = ({ id }) => {
  const { callApi } = useProtectedApi();
  const [formData, setFormData] = useState({});
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getVisitData = async () => {
    try {
      let res = await callApi("/print/clearance/payload", {
        visitId: id.split("_")[0],
      });
      setData(res);
      setIsLoading(false);
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVisitData();
  }, [id]);

  const ps = () => {
    if (isLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    if (!data) {
      return null;
    }
    const protocolId = id.split("_")[1];
    let bp = data.protocolsInfo;
    let p = _.find(bp, { _id: protocolId });
    let ps = data.protocolClearance;
    let protocolSummaryObj = ps[protocolId];
    return protocolSummaryObj;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }
  if (!data) {
    return <div>No data</div>;
  }

  return (
    <FormContainer>
      <Header>
        <div>
          <Box
            component="img"
            src="/logo/arkema-logo.webp"
            sx={{ width: 300, height: 50, cursor: "pointer" }}
          />
        </div>
        <div>
          <Title>Pre - Assignment</Title>
          <Title>Physical Qualifications</Title>
          <Title>Placement Report</Title>
        </div>
      </Header>

      <Table>
        <tbody>
          <tr>
            <Td>Employee ID Number *: {data.employee.employeeNumber}</Td>
            <Td>
              Date: {ps().clearedAt ? format(ps().clearedAt, "MM/dd/yyyy") : ""}
            </Td>
          </tr>
          <tr>
            <Td colSpan="2">
              Name (last, first, middle): {data.employee.employeeName}
            </Td>
          </tr>
          <tr>
            <Td>Work Location: {data.employee.workLocation}</Td>
            <Td>Job Classification: {data.employee.jobClassification}</Td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <tbody>
          <tr>
            <Td>
              <SectionTitle>A. Qualifications (General)</SectionTitle>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="noLimitations"
                  onChange={handleChange}
                  checked={
                    ps().arkemaGeneralQualifications === "NO_LIMITATIONS"
                  }
                />
                No limitations
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="withLimitations"
                  onChange={handleChange}
                  checked={
                    ps().arkemaGeneralQualifications === "WITH_LIMITATIONS"
                  }
                />
                Limitations as listed in comments or section F below
              </CompactDiv>
            </Td>
            <Td>
              <SectionTitle>B. Emergency Responders</SectionTitle>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="erCleared"
                  onChange={handleChange}
                  checked={ps().arkemaErt === "ARKEMA_ERT_CLEARED"}
                />
                Cleared
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="erNotCleared"
                  onChange={handleChange}
                  checked={ps().arkemaErt === "ARKEMA_ERT_NOT_CLEARED"}
                />
                Not cleared
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="erClearedWithLimitations"
                  onChange={handleChange}
                  checked={
                    ps().arkemaErt === "ARKEMA_ERT_CLEARED_WITH_LIMITATION"
                  }
                />
                Cleared with limitations - see comments below
              </CompactDiv>
            </Td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <tbody>
          <tr>
            <Td>
              <SectionTitle>C. Motor Vehicle Operation</SectionTitle>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="mvoCleared"
                  onChange={handleChange}
                  checked={ps().arkemaMvo === "ARKEMA_MVO_CLEARED"}
                />
                Cleared
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="mvoNotCleared"
                  onChange={handleChange}
                  checked={ps().arkemaMvo === "ARKEMA_MVO_NOT_CLEARED"}
                />
                Not cleared
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="mvoClearedWithLimitations"
                  onChange={handleChange}
                  checked={
                    ps().arkemaMvo === "ARKEMA_MVO_CLEARED_WITH_LIMITATION"
                  }
                />
                Cleared with limitations - see comments below
              </CompactDiv>
            </Td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <tbody>
          <tr>
            <Td>
              <SectionTitle>
                E. Respiratory Clearance (Provide examinee with copy)
              </SectionTitle>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="rcNotCleared"
                  onChange={handleChange}
                  checked={(ps().arkemaRc || []).includes(
                    "ARKEMA_RC_NOT_CLEARED"
                  )}
                />
                Not cleared to use any type of respirator
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="rcNegativePressure"
                  onChange={handleChange}
                  checked={(ps().arkemaRc || []).includes("ARKEMA_RC_NEG_PAPR")}
                />
                Use of a negative pressure air-purifying respirator
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="rcSuppliedAir"
                  onChange={handleChange}
                  checked={(ps().arkemaRc || []).includes("ARKEMA_RC_PAPR")}
                />
                Use of supplied air (PAPR) or airline
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="rcSCBA"
                  onChange={handleChange}
                  checked={(ps().arkemaRc || []).includes("ARKEMA_RC_SCBA")}
                />
                Use of a Self Contained Breathing Apparatus (SCBA)
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="rcCorrectiveLens"
                  onChange={handleChange}
                  checked={(ps().arkemaRc || []).includes(
                    "ARKEMA_RC_CORRECTIVE_LENS"
                  )}
                />
                Corrective lens req. for use with full face respirator
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="rcUsageLimitations"
                  onChange={handleChange}
                  checked={(ps().arkemaRc || []).includes(
                    "ARKEMA_RC_USAGE_LIMITATIONS"
                  )}
                />
                Respirator Usage Limitations
              </CompactDiv>
            </Td>
            <Td>
              <SectionTitle>D. Hearing Conservation Program</SectionTitle>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="hcpInitialBaseline"
                  onChange={handleChange}
                  checked={ps().arkemaHcp === "ARKEMA_HCP_INITIAL_BASELINE"}
                />
                Initial / Baseline Audiogram
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="hcpNoChange"
                  onChange={handleChange}
                  checked={ps().arkemaHcp === "ARKEMA_HCP_NO_CHANGE"}
                />
                No change in baseline
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="hcpInitialSTS"
                  onChange={handleChange}
                  checked={ps().arkemaHcp === "ARKEMA_HCP_INITIAL_STS"}
                />
                Initial STS-RETEST
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="hcpConfirmedSTS"
                  onChange={handleChange}
                  checked={ps().arkemaHcp === "ARKEMA_HCP_CONFIRMED_STS"}
                />
                Confirmed STS
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="hcpConfirmedSTSHighShift"
                  onChange={handleChange}
                  checked={
                    ps().arkemaHcp === "ARKEMA_HCP_CONFIRMED_STS_HIGH_SHIFT"
                  }
                />
                Confirmed STS and &gt;25dB avg. shift from audiometric 0
              </CompactDiv>
            </Td>
          </tr>
        </tbody>
      </Table>

      <SectionTitle>F. Limitations, Risk Factors:</SectionTitle>
      <Table>
        <tbody>
          <tr>
            <Td>
              <div>Ambulation:</div>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="standingLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("STANDING_LIMIT")}
                />
                Standing more than _____ min. per/hr.
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="walkingLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("WALKING_LIMIT")}
                />
                Walking more than _____ yrd. per/hr.
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="heightsLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("HEIGHTS_LIMIT")}
                />
                Working on heights over _____ ft.
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="pushPullLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("PUSH_PULL_LIMIT")}
                />
                Pushing or pulling than _____ lbs.
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="bendingLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("BENDING_LIMIT")}
                />
                Stooping, crouching or bending
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="laddersLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("LADDERS_LIMIT")}
                />
                Climbing ladders
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="stairsLimit"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("STAIRS_LIMIT")}
                />
                Climbing stairs
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="ambulationOther"
                  onChange={handleChange}
                  checked={ps().arkemaAmbulation?.includes("OTHER")}
                />
                Other: _________________
              </CompactDiv>
            </Td>
            <Td>
              <div>Environment:</div>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="extremeHeat"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("EXTREME_HEAT")}
                />
                Extreme heat
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="extremeCold"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("EXTREME_COLD")}
                />
                Extreme cold
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="wetDamp"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("WET_DAMP")}
                />
                Wet or damp areas
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="dustFumes"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("DUST_FUMES")}
                />
                Dust or fumes
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="sunExposure"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("SUN_EXPOSURE")}
                />
                Excessive sun exposure
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="shiftWork"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("SHIFT_WORK")}
                />
                Shift work
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="overtimeWork"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("OVERTIME_WORK")}
                />
                Overtime work
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="outdoorWork"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("OUTDOOR_WORK")}
                />
                Outdoor work
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="confinedSpaces"
                  onChange={handleChange}
                  checked={ps().arkemaEnvironment?.includes("CONFINED_SPACES")}
                />
                Work in confined spaces
              </CompactDiv>
            </Td>
          </tr>
          <tr>
            <Td>
              <div>Hearing / Ears:</div>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workWithoutEarProtection"
                  onChange={handleChange}
                  checked={ps().arkemaHearing?.includes(
                    "WITHOUT_EAR_PROTECTION"
                  )}
                />
                Working w/o ear protection in identified noisy environment
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workWithoutHearingAid"
                  onChange={handleChange}
                  checked={ps().arkemaHearing?.includes("WITHOUT_HEARING_AID")}
                />
                Working without hearing aid
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringNonperforatedEardrums"
                  onChange={handleChange}
                  checked={ps().arkemaHearing?.includes(
                    "REQUIRING_NONPERFORATED_EARDRUMS"
                  )}
                />
                Working in job requiring nonperforated eardrums (e.g. toxic gas
                exposure)
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringGoodHearing"
                  onChange={handleChange}
                  checked={ps().arkemaHearing?.includes(
                    "REQUIRING_GOOD_HEARING"
                  )}
                />
                Working in job requiring good hearing
              </CompactDiv>
            </Td>
            <Td>
              <div>Vision:</div>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workWithoutVisualCorrection"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes(
                    "WITHOUT_VISUAL_CORRECTION"
                  )}
                />
                Working w/o visual correction on job
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workInPoorlyLitArea"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes("POORLY_LIT_AREA")}
                />
                Working in poorly lighted area
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringColorDistinction"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes(
                    "REQUIRING_COLOR_DISTINCTION"
                  )}
                />
                Working in job req. color distinction
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringNearVision"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes("REQUIRING_NEAR_VISION")}
                />
                Working in job req. near vision
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringFarVision"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes("REQUIRING_FAR_VISION")}
                />
                Working in job req. good far vision
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringDepthPerception"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes(
                    "REQUIRING_DEPTH_PERCEPTION"
                  )}
                />
                Working in job req. depth perception
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringLateralVision"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes(
                    "REQUIRING_LATERAL_VISION"
                  )}
                />
                Working in job req. good lateral vision
              </CompactDiv>
              <CompactDiv>
                <Checkbox
                  type="checkbox"
                  name="workRequiringBothEyes"
                  onChange={handleChange}
                  checked={ps().arkemaVision?.includes("REQUIRING_BOTH_EYES")}
                />
                Working in job req. good vision in both eyes
              </CompactDiv>
            </Td>
          </tr>
        </tbody>
      </Table>

      <div style={{ marginTop: "35mm" }}>
        <Table>
          <tbody>
            <tr>
              <Td>
                <div>Irritants: Unprotected exposure to:</div>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="irritatingOils"
                    onChange={handleChange}
                    checked={ps().arkemaIrritants?.includes("IRRITATING_OILS")}
                  />
                  Irritating oils and coolants
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="solvents"
                    onChange={handleChange}
                    checked={ps().arkemaIrritants?.includes("SOLVENTS")}
                  />
                  Solvents
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="soapsDetergents"
                    onChange={handleChange}
                    checked={ps().arkemaIrritants?.includes("SOAPS_DETERGENTS")}
                  />
                  Soaps and detergents
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="irritatingChemicals"
                    onChange={handleChange}
                    checked={ps().arkemaIrritants?.includes(
                      "IRRITATING_CHEMICALS"
                    )}
                  />
                  Irritating chemicals
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="otherIrritants"
                    onChange={handleChange}
                    checked={ps().arkemaIrritants?.includes("OTHER")}
                  />
                  Other allergens, sensitizers or irritants (specify):
                  _________________
                </CompactDiv>
              </Td>
              <Td>
                <div>Machinery:</div>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="drivingCar"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes("DRIVING_CAR")}
                  />
                  Driving car or pickup
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="drivingTruck"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes("DRIVING_TRUCK")}
                  />
                  Driving truck
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="drivingTractor"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes("DRIVING_TRACTOR")}
                  />
                  Driving tractor (off highway)
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="operatingHazardousMachinery"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes(
                      "OPERATING_HAZARDOUS_MACHINERY"
                    )}
                  />
                  Operating or working near moving and/or hazardous machinery
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="drivingUnderground"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes(
                      "DRIVING_UNDERGROUND"
                    )}
                  />
                  Driving underground
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="drivingForklift"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes("DRIVING_FORKLIFT")}
                  />
                  Driving forklift or other plant equipment
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="machineryOther"
                    onChange={handleChange}
                    checked={ps().arkemaMachinery?.includes("OTHER")}
                  />
                  Other: _________________
                </CompactDiv>
              </Td>
            </tr>
            <tr>
              <Td>
                <div>Lifting:</div>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="lifting50Pounds"
                    onChange={handleChange}
                    checked={ps().arkemaLifting?.includes("OVER_50_POUNDS")}
                  />
                  Over 50 pounds (23 kilos)
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="lifting25Pounds"
                    onChange={handleChange}
                    checked={ps().arkemaLifting?.includes("OVER_25_POUNDS")}
                  />
                  Over 25 pounds (11 kilos)
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="lifting10Pounds"
                    onChange={handleChange}
                    checked={ps().arkemaLifting?.includes("OVER_10_POUNDS")}
                  />
                  Over 10 pounds (4.5 kilos)
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="liftingAny"
                    onChange={handleChange}
                    checked={ps().arkemaLifting?.includes(
                      "ANY_CARRYING_OR_LIFTING"
                    )}
                  />
                  Any carrying or lifting
                </CompactDiv>
              </Td>
              <Td>
                <div>G. Other:</div>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="manualLabor"
                    onChange={handleChange}
                    checked={ps().arkemaOther?.includes("MANUAL_LABOR")}
                  />
                  Manual Labor
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="heavyManualLabor"
                    onChange={handleChange}
                    checked={ps().arkemaOther?.includes("HEAVY_MANUAL_LABOR")}
                  />
                  Heavy manual labor
                </CompactDiv>
                <CompactDiv>
                  <Checkbox
                    type="checkbox"
                    name="otherLimitations"
                    onChange={handleChange}
                    checked={ps().arkemaOther?.includes("OTHER")}
                  />
                  Other: _________________
                </CompactDiv>
              </Td>
            </tr>
          </tbody>
        </Table>
        <SectionTitle>H. Chemical Specific Evaluation *</SectionTitle>
        <SmallText>
          (e.g. Asbestos, Acrylonitrile, Benzene, Butadiene, Chromium VI,
          Formaldehyde, Methylene Chloride, other)
        </SmallText>
        <div style={{ marginTop: "2mm", marginBottom: "2mm" }}>
          Name Chemical(s) Here:{" "}
          <input
            type="text"
            name="chemicals"
            onChange={handleChange}
            value={ps().arkemaChemicals}
          />
        </div>
        <CompactDiv>
          <Checkbox
            type="checkbox"
            name="ceNoRisk"
            onChange={handleChange}
            checked={ps().arkemaChem === "ARKEMA_CHEM_OK"}
          />
          I have detected no medical conditions that would place the employee at
          an increased risk of material health impairment from exposure to the
          chemical(s) identified above.
        </CompactDiv>
        <CompactDiv>
          <Checkbox
            type="checkbox"
            name="ceRiskExists"
            onChange={handleChange}
            checked={ps().arkemaChem === "ARKEMA_CHEM_AT_RISK"}
          />
          Medical conditions exist that may place the employee at an increased
          risk of material health impairment from exposure to the chemical(s)
          identified above. (If increase risk conditions have been detected,
          describe any recommended limitations to chemical exposure and / or PPE
          use under Comments below.
        </CompactDiv>
        <CompactDiv>
          <Checkbox
            type="checkbox"
            name="ceEmployeeInformed"
            onChange={handleChange}
            checked={ps().arkemaChem === "ARKEMA_CHEM_NOT_OK"}
          />
          The employee has been informed, by me, of the results of the medical
          examination and of any medical conditions resulting from exposure to
          the chemicals listed above that require further examination or
          treatment.
        </CompactDiv>
      </div>

      <div style={{ marginTop: "6mm" }}>
        <SectionTitle>I. Comments:</SectionTitle>
        <TextArea
          name="comments"
          onChange={handleChange}
          value={ps().finalClearanceText}
        />
      </div>

      <SmallText style={{ marginTop: "4mm", marginBottom: "4mm" }}>
        Note to attending physician: Do not reveal any specific findings or
        diagnoses on this form. That information can be recorded on the Arkema
        Physical Examination Form. Arkema will provide a copy of this completed
        form to the employee.
      </SmallText>

      <Table>
        <tbody>
          <tr>
            <Td>
              Return-to-Duty Date (if applicable):{" "}
              {ps().arkemaRtd ? format(ps().arkemaRtd, "MM/dd/yyyy") : ""}
            </Td>
            <Td>Duration of Limitation: {ps().arkemaDuration}</Td>
            <Td>Follow-up Medical Evaluation Advised: {ps().arkemaFollowUp}</Td>
          </tr>
        </tbody>
      </Table>

      <Table>
        <tbody>
          <tr>
            <Td>Physician Name: {data.assignedMD.name}</Td>
            <Td>
              <Box
                component="img"
                src={data.assignedMD.signatureImagePsUrl}
                sx={{ height: "30px", width: "200px", cursor: "pointer" }}
              />
            </Td>
            <Td>
              Date: {ps().clearedAt ? format(ps().clearedAt, "MM/dd/yyyy") : ""}
            </Td>
          </tr>
          <tr>
            <Td>Employee's Name: {data.employee.employeeName}</Td>
            <Td>Employee's Signature: ________________</Td>
            <Td>Date: ________________</Td>
          </tr>
        </tbody>
      </Table>
      <SmallText>
        * Social Security Number required if exam includes chemical specific
        evaluation covered by section H
      </SmallText>
    </FormContainer>
  );
};

export default FormPreEmploymentForArkema;
