import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Grid from "@mui/material/Unstable_Grid2";
import ListItemText from "@mui/material/ListItemText";
import FormControlLabel from "@mui/material/FormControlLabel";

import React from "react";
import {
  Chip,
  Popover,
  TextField,
  Select,
  MenuItem,
  LinearProgress,
} from "@mui/material";
import { WithAPICall } from "../utils/apiUtil";
import cogoToast from "cogo-toast";
import { DayPicker } from "react-day-picker";
import { niceDate } from "../utils/fn";
import { Box } from "@mui/system";
import { formFoxReasonForTest } from "src/assets/data/formfox";

class MasterProtocolSettings extends React.Component {
  state = {
    searchInput: "",
    selectedNetwork: null,
    searchResults: [],
    isSearching: false,
    isEditingNetwork: false,
    isLoading: true,
  };
  componentDidMount() {
    this.setState({ ...this.props.data, isLoading: false });
  }
  SETTINGS = [
    {
      subheader: "Protocol Information",
      caption: "Details about this Protocol",
      items: [
        {
          id: "masterProtocolName",
          label: "Name of Protocol",
          type: "TEXT",
        },
        {
          id: "masterProtocolCode",
          label: "Company Protocol Code",
          type: "TEXT",
        },
        {
          id: "isPool",
          label: "Is this protocol for a pooled draw?",
          type: "SWITCH",
        },
        {
          id: "drawRequirement",
          label: "Draw Requirement For Protocol (%)",
          type: "TEXT",
          isNumber: true,
        },
        {
          id: "masterProtocolType",
          label: "Type of Protocol",
          type: "OPTIONS",
          options: ["Preplacement", "Periodic", "Exit", "Targeted"],
        },
        {
          id: "masterProtocolFrequencyValue",
          label: "Protocol Frequency (Value)",
          type: "TEXT",
          isNumber: true,
        },
        {
          id: "masterProtocolFrequencyUnit",
          label: "Protocol Frequency (Unit)",
          type: "OPTIONS",
          options: ["Day(s)", "Week(s)", "Month(s)", "Year(s)"],
        },
        {
          id: "activeStatus",
          label: "Active Status",
          type: "OPTIONS",
          options: ["ACTIVE", "TEMP_CLOSED", "PERM_CLOSED"],
        },
        {
          id: "isDot",
          label: "Is this a DOT protocol?",
          type: "SWITCH",
        },
        {
          id: "regulatoryAuthority",
          label: "Regulatory Authority",
          type: "OPTIONS",
          options: [
            "FMCSA",
            "FAA",
            "PHMSA",
            "FRA",
            "USCG",
            "FTA",
            "NRC",
            "NHS",
            "<None>",
          ],
        },
        {
          id: "splitSample",
          label: "Split Sample",
          type: "SWITCH",
        },
        {
          id: "requestObservation",
          label: "Request Observation",
          type: "SWITCH",
        },
      ],
    },
  ];

  updateScalarValue = async (key, value) => {
    try {
      await this.props.apiCallPost(
        "/master-protocol/masterProtocolUpdateScalarValue",
        {
          masterProtocolId: this.props.protocolId,
          key: key,
          value: value,
        }
      );
    } catch (err) {
      cogoToast.error("Error Updating Protocol Data");
      console.log(err);
    }
  };
  renderControl = (type, item) => {
    switch (type) {
      case "SWITCH":
        return (
          <Switch
            disabled={item.nonEditable}
            checked={this.state[item.id]}
            onChange={() => {
              this.setState(
                {
                  [item.id]: !this.state[item.id],
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          />
        );
      case "OPTIONS":
        return (
          <Select
            sx={{ minWidth: 200 }}
            value={this.state[item.id]}
            onChange={(e) => {
              this.setState(
                {
                  [item.id]: e.target.value,
                },
                () => {
                  this.updateScalarValue(item.id, this.state[item.id]);
                }
              );
            }}
          >
            {item.options.map((each) => (
              <MenuItem value={each}>{each}</MenuItem>
            ))}
          </Select>
        );
      case "TEXT":
        return (
          <TextField
            value={this.state[item.id]}
            onChange={(e) => {
              let x = e.target.value;
              if (item.isNumber) {
                x = parseInt(x);
              }
              this.setState({
                [item.id]: x,
              });
            }}
            onBlur={() => {
              this.updateScalarValue(item.id, this.state[item.id]);
            }}
          />
        );
      case "DATE":
        return (
          <>
            <Chip
              variant="contained"
              onClick={(e) => {
                this.setState({
                  anchorEl: e.currentTarget,
                  [`open_${item.label}`]: true,
                });
              }}
              label={
                this.state[item.id] ? niceDate(this.state[item.id]) : "Not Set"
              }
            />
            <Popover
              open={this.state[`open_${item.label}`]}
              anchorEl={this.state.anchorEl}
              onClose={() => {
                this.setState({
                  anchorEl: null,
                  [`open_${item.label}`]: false,
                });
              }}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
            >
              <DayPicker
                mode="single"
                captionLayout="dropdown-buttons"
                fromYear={1901}
                toYear={2024}
                onSelect={(e) => {
                  this.setState({
                    [item.id]: e,
                    anchorEl: null,
                    [`open_${item.label}`]: false,
                  });
                  this.updateScalarValue(item.id, e);
                }}
              />
            </Popover>
          </>
        );
    }
  };
  COST_MODEL_OPTIONS = ["FLAT_RATE", "USAGE_BASED", "HYBRID"];
  renderCostModelOptions = () => {
    if (!this.state.costModel) return null;
    let cm = this.state.costModel;
    switch (cm) {
      case "FLAT_RATE":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(1, 1fr)",
            }}
          >
            <TextField
              value={this.state.baseCostUsd}
              onChange={(e) => {
                this.setState({
                  baseCostUsd: e.target.value,
                });
              }}
              label="Flat Rate (USD)"
              onBlur={() => {
                this.updateScalarValue(
                  "baseCostUsd",
                  parseFloat(this.state.baseCostUsd)
                );
              }}
            />
          </Box>
        );
      case "USAGE_BASED":
        return (
          <Box
            rowGap={2}
            columnGap={2}
            display="grid"
            gridTemplateColumns={{
              xs: "repeat(1, 1fr)",
              sm: "repeat(2, 1fr)",
            }}
          >
            <TextField
              value={this.state.unitCostUsd}
              onChange={(e) => {
                this.setState({
                  unitCostUsd: e.target.value,
                });
              }}
              label="Unit Rate (USD)"
              onBlur={() => {
                this.updateScalarValue(
                  "unitCostUsd",
                  parseFloat(this.state.unitCostUsd)
                );
              }}
            />
            <TextField
              value={this.state.costModelUnitOfWork}
              onChange={(e) => {
                this.setState({
                  costModelUnitOfWork: e.target.value,
                });
              }}
              label="Unit"
              onBlur={() => {
                this.updateScalarValue(
                  "costModelUnitOfWork",
                  this.state.costModelUnitOfWork
                );
              }}
            />
          </Box>
        );
      case "HYBRID":
        return (
          <Stack spacing={2}>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(1, 1fr)",
              }}
            >
              <TextField
                value={this.state.baseCostUsd}
                onChange={(e) => {
                  this.setState({
                    baseCostUsd: e.target.value,
                  });
                }}
                label="Flat Rate (USD)"
                onBlur={() => {
                  this.updateScalarValue(
                    "baseCostUsd",
                    parseFloat(this.state.baseCostUsd)
                  );
                }}
              />
            </Box>
            <Box
              rowGap={2}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: "repeat(1, 1fr)",
                sm: "repeat(2, 1fr)",
              }}
            >
              <TextField
                value={this.state.unitCostUsd}
                onChange={(e) => {
                  this.setState({
                    unitCostUsd: e.target.value,
                  });
                }}
                label="Unit Rate (USD)"
                onBlur={() => {
                  this.updateScalarValue(
                    "unitCostUsd",
                    parseFloat(this.state.unitCostUsd)
                  );
                }}
              />
              <TextField
                value={this.state.costModelUnitOfWork}
                onChange={(e) => {
                  this.setState({
                    costModelUnitOfWork: e.target.value,
                  });
                }}
                label="Unit"
                onBlur={() => {
                  this.updateScalarValue(
                    "costModelUnitOfWork",
                    this.state.costModelUnitOfWork
                  );
                }}
              />
            </Box>
          </Stack>
        );
      default:
        return null;
    }
  };
  renderCostModel = () => {
    return (
      <Stack spacing={2}>
        <Box
          rowGap={2}
          columnGap={2}
          display="grid"
          gridTemplateColumns={{
            xs: "repeat(1, 1fr)",
            sm: "repeat(1, 1fr)",
          }}
        >
          <Select
            value={this.state.costModel}
            onChange={(e) => {
              this.setState(
                {
                  costModel: e.target.value,
                  costModelUnitOfWork: null,
                  baseCostUsd: null,
                  unitCostUsd: null,
                },
                () => {
                  this.updateScalarValue("costModel", e.target.value);
                  this.updateScalarValue("costModelUnitOfWork", null);
                  this.updateScalarValue("baseCostUsd", null);
                  this.updateScalarValue("unitCostUsd", null);
                }
              );
            }}
            sx={{
              minWidth: "100px",
            }}
          >
            {this.COST_MODEL_OPTIONS.map((each) => {
              return <MenuItem value={each}>{each}</MenuItem>;
            })}
          </Select>
        </Box>
        {this.renderCostModelOptions()}
      </Stack>
    );
  };
  render() {
    if (this.state.isLoading) return <LinearProgress />;
    return (
      <div>
        <Stack component={Card} spacing={3} sx={{ p: 3 }}>
          {this.SETTINGS.map((setting, index) => {
            return (
              <Grid key={setting.subheader} container spacing={3}>
                <Grid xs={12} md={4}>
                  <ListItemText
                    primary={setting.subheader}
                    secondary={setting.caption}
                    primaryTypographyProps={{ typography: "h6", mb: 0.5 }}
                    secondaryTypographyProps={{ component: "span" }}
                  />
                </Grid>
                <Grid xs={12} md={8}>
                  <Stack
                    spacing={1}
                    sx={{
                      p: 3,
                      borderRadius: 2,
                      bgcolor: "background.neutral",
                    }}
                  >
                    <>
                      {setting.items.map((item, subIndex) => {
                        if (
                          item.id === "drawRequirement" &&
                          !this.state.isPool
                        ) {
                          return null;
                        }
                        return (
                          <FormControlLabel
                            key={item.id}
                            label={item.label}
                            labelPlacement="start"
                            control={this.renderControl(item.type, item)}
                            sx={{
                              m: 0,
                              width: 1,
                              justifyContent: "space-between",
                            }}
                          />
                        );
                      })}
                    </>
                    <FormControlLabel
                      label="Cost Model"
                      labelPlacement="start"
                      control={this.renderCostModel()}
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                    <FormControlLabel
                      label="Reason for Test"
                      labelPlacement="start"
                      control={
                        <Select
                          width="100%"
                          value={this.state.formFoxReasonForTest?.id}
                          onChange={(e) => {
                            const ffReason = formFoxReasonForTest.find(
                              (f) => f.id === e.target.value
                            );
                            if (ffReason) {
                              this.setState(
                                {
                                  formFoxReasonForTest: ffReason,
                                },
                                () => {
                                  this.updateScalarValue(
                                    "formFoxReasonForTest",
                                    ffReason
                                  );
                                }
                              );
                            }
                          }}
                        >
                          {formFoxReasonForTest.map((testType) => (
                            <MenuItem key={testType.id} value={testType.id}>
                              {testType.name}
                            </MenuItem>
                          ))}
                        </Select>
                      }
                      sx={{
                        m: 0,
                        width: 1,
                        justifyContent: "space-between",
                      }}
                    />
                  </Stack>
                </Grid>
              </Grid>
            );
          })}
        </Stack>
      </div>
    );
  }
}
export default WithAPICall(MasterProtocolSettings);
