import React, { useState, useEffect } from "react";
import { Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import _ from "lodash";
import { useProtectedApi } from "@/hooks/use-apiCall";

const FormContainer = styled("div")({
  width: "210mm",
  height: "250mm",
  padding: "10mm",
  margin: "auto",
  fontFamily: "Arial, sans-serif",
  fontSize: "10pt",
  lineHeight: "1.2",
  backgroundColor: "#fff",
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  marginBottom: "15mm",
});

const Title = styled("h3")({
  fontSize: "14pt",
  fontWeight: "bold",
  margin: "0",
});

const Table = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "6mm",
});

const Td = styled("td")({
  border: "1px solid black",
  padding: "2mm 3mm",
  height: "8mm",
  verticalAlign: "top",
});

const SectionTitle = styled("div")({
  fontWeight: "bold",
  marginBottom: "2mm",
});

const Checkbox = styled("input")({
  marginRight: "2mm",
});

const TextArea = styled("textarea")({
  width: "100%",
  height: "15mm",
  border: "1px solid black",
  fontSize: "10pt",
  padding: "2mm",
});

const SmallText = styled("div")({
  fontSize: "8pt",
  marginTop: "2mm",
});

const PeriodicExamForm = ({ id }) => {
  console.log("id", id);
  const { callApiUnAuthenticated } = useProtectedApi();
  const [formData, setFormData] = useState({});
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getVisitData = async () => {
    try {
      let res = await callApiUnAuthenticated("/print/clearance/payload", {
        visitId: id.split("_")[0],
      });
      setData(res);
      setIsLoading(false);
    } catch (err) {
      console.log("err", err);
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    console.log("in useEffect", id);
    getVisitData();
  }, [id]);

  const ps = () => {
    if (isLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    if (!data) {
      return null;
    }
    const protocolId = id.split("_")[1];
    let bp = data.protocolsInfo;
    let p = _.find(bp, { _id: protocolId });
    let ps = data.protocolClearance;
    let protocolSummaryObj = ps[protocolId];
    return protocolSummaryObj;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }
  if (isError) {
    return <div>Error</div>;
  }
  if (!data) {
    return <div>No data</div>;
  }

  return (
    <FormContainer>
      <div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <div>
            <Box
              component="img"
              src="/logo/arkema-logo.webp"
              sx={{ width: 300, height: 50, cursor: "pointer" }}
            />
          </div>
          <div>
            <h3>
              <strong>PERIODIC EXAM</strong>
            </h3>
            <h3>
              <strong>PHYSICAL QUALIFICATIONS</strong>
            </h3>
            <h3>
              <strong>PLACEMENT REPORT</strong>
            </h3>
          </div>
        </div>

        <Table>
          <tbody>
            <tr>
              <Td>Name: {data.employee.employeeName}</Td>
              <Td>Employee ID Number: {data.employee.employeeNumber}</Td>
              <Td>
                Date:{" "}
                {ps().clearedAt ? format(ps().clearedAt, "MM/dd/yyyy") : ""}
              </Td>
            </tr>
            <tr>
              <Td style={{ width: "50%" }}>
                Work Location: {data?.employee?.employeeWorkLocation || ""}
              </Td>
              <Td style={{ width: "50%" }} colSpan="2">
                Job Classification: {data?.employee?.employeeJobTitle || ""}
              </Td>
            </tr>
          </tbody>
        </Table>

        <Table>
          <tbody>
            <tr>
              <Td>
                <SectionTitle>A. Emergency Responders</SectionTitle>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="erCleared"
                    onChange={handleChange}
                    checked={ps().arkemaErt === "ARKEMA_ERT_CLEARED"}
                  />
                  Cleared
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="erNotCleared"
                    onChange={handleChange}
                    checked={ps().arkemaErt === "ARKEMA_ERT_NOT_CLEARED"}
                  />{" "}
                  Not cleared
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="erClearedWithLimitations"
                    onChange={handleChange}
                    checked={
                      ps().arkemaErt === "ARKEMA_ERT_CLEARED_WITH_LIMITATION"
                    }
                  />{" "}
                  Cleared with limitations - see comments below
                </div>
              </Td>
              <Td>
                <SectionTitle>B. Motor Vehicle Operation</SectionTitle>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="mvoCleared"
                    onChange={handleChange}
                    checked={ps().arkemaMvo === "ARKEMA_MVO_CLEARED"}
                  />{" "}
                  Cleared
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="mvoNotCleared"
                    onChange={handleChange}
                    checked={ps().arkemaMvo === "ARKEMA_MVO_NOT_CLEARED"}
                  />{" "}
                  Not cleared
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="mvoClearedWithLimitations"
                    onChange={handleChange}
                    checked={
                      ps().arkemaMvo === "ARKEMA_MVO_CLEARED_WITH_LIMITATION"
                    }
                  />{" "}
                  Cleared with limitations - see comments below
                </div>
              </Td>
            </tr>
          </tbody>
        </Table>

        <Table>
          <tbody>
            <tr>
              <Td>
                <SectionTitle>
                  C. Respiratory Clearance (Provide examinee with copy)
                </SectionTitle>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="rcNotCleared"
                    onChange={handleChange}
                    checked={(ps().arkemaRc || []).includes(
                      "ARKEMA_RC_NOT_CLEARED"
                    )}
                  />{" "}
                  Not cleared to use any type of respirator
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="rcNegativePressure"
                    onChange={handleChange}
                    checked={(ps().arkemaRc || []).includes(
                      "ARKEMA_RC_NEG_PAPR"
                    )}
                  />{" "}
                  Use of a negative pressure air-purifying respirator
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="rcSuppliedAir"
                    onChange={handleChange}
                    checked={(ps().arkemaRc || []).includes("ARKEMA_RC_PAPR")}
                  />{" "}
                  Use of supplied air (PAPR) or airline
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="rcSCBA"
                    onChange={handleChange}
                    checked={(ps().arkemaRc || []).includes("ARKEMA_RC_SCBA")}
                  />{" "}
                  Use of a Self Contained Breathing Apparatus (SCBA)
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="rcCorrectiveLens"
                    onChange={handleChange}
                    checked={(ps().arkemaRc || []).includes(
                      "ARKEMA_RC_CORRECTIVE_LENS"
                    )}
                  />{" "}
                  Corrective lens req. for use with full face respirator
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="rcUsageLimitations"
                    onChange={handleChange}
                    checked={(ps().arkemaRc || []).includes(
                      "ARKEMA_RC_USAGE_LIMITATIONS"
                    )}
                  />{" "}
                  Respirator Usage Limitations
                </div>
              </Td>
              <Td>
                <SectionTitle>D. Hearing Conservation Program</SectionTitle>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="hcpNoChange"
                    onChange={handleChange}
                    checked={ps().arkemaHcp === "ARKEMA_HCP_NO_CHANGE"}
                  />{" "}
                  No change in baseline
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="hcpInitialSTS"
                    onChange={handleChange}
                    checked={ps().arkemaHcp === "ARKEMA_HCP_INITIAL_STS"}
                  />{" "}
                  Initial STS-RETEST
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="hcpConfirmedSTS"
                    onChange={handleChange}
                    checked={ps().arkemaHcp === "ARKEMA_HCP_CONFIRMED_STS"}
                  />{" "}
                  Confirmed STS
                </div>
                <div>
                  <Checkbox
                    type="checkbox"
                    name="hcpShift25dB"
                    onChange={handleChange}
                    checked={ps().arkemaHcp === "ARKEMA_HCP_HIGH_SHIFT"}
                  />{" "}
                  &gt;25dB shift from original baseline
                </div>
              </Td>
            </tr>
          </tbody>
        </Table>

        <div style={{ marginBottom: "6mm" }}>
          <SectionTitle>E. Chemical Specific Evaluation *</SectionTitle>
          <SmallText>
            (e.g. Asbestos, Acrylonitrile, Benzene, Butadiene, Chromium VI,
            Formaldehyde, Methylene Chloride, other)
          </SmallText>
          <div style={{ marginTop: "2mm", marginBottom: "2mm" }}>
            Name Chemical(s) Here:
          </div>
          <div>
            <Checkbox
              type="checkbox"
              name="ceNoRisk"
              onChange={handleChange}
              checked={ps().arkemaChem === "ARKEMA_CHEM_OK"}
            />{" "}
            I have detected no medical conditions that would place the employee
            at an increased risk of material health impairment from exposure to
            the chemical(s) identified above.
          </div>
          <div>
            <Checkbox
              type="checkbox"
              name="ceRiskExists"
              onChange={handleChange}
              checked={ps().arkemaChem === "ARKEMA_CHEM_AT_RISK"}
            />{" "}
            Medical conditions exist that may place the employee at an increased
            risk of material health impairment from exposure to the chemical(s)
            identified above. (If increase risk conditions have been detected,
            describe any recommended limitations to chemical exposure and / or
            PPE use under Comments below.
          </div>
          <div>
            <Checkbox
              type="checkbox"
              name="ceEmployeeInformed"
              onChange={handleChange}
              checked={ps().arkemaChem === "ARKEMA_CHEM_NOT_OK"}
            />{" "}
            The employee has been informed, by me, of the results of the medical
            examination and of any medical conditions resulting from exposure to
            the chemicals listed above that require further examination or
            treatment.
          </div>
        </div>

        <div style={{ marginBottom: "3mm" }}>
          <SectionTitle>G. Comments:</SectionTitle>
          <TextArea
            name="comments"
            onChange={handleChange}
            value={ps().finalClearanceText}
          />
        </div>

        <SmallText style={{ marginBottom: "4mm" }}>
          Note to attending physician: Do not reveal any specific findings or
          diagnoses on this form. That information can be recorded on the Arkema
          Physical Examination Form. Arkema will provide a copy of this
          completed form to the employee.
        </SmallText>

        <Table>
          <tbody>
            <tr>
              <Td style={{ height: "44px" }}>
                Return-to-Duty Date (if applicable):{" "}
                {ps().arkemaRtd ? format(ps().arkemaRtd, "MM/dd/yyyy") : ""}
              </Td>
              <Td style={{ height: "44px" }}>
                Duration of Limitation: {ps().arkemaDuration}
              </Td>
              <Td style={{ height: "44px" }}>
                Follow-up Medical Evaluation Advised: {ps().arkemaFollowUp}
              </Td>
            </tr>
          </tbody>
        </Table>

        <Table>
          <tbody>
            <tr>
              <Td>Physician Name: {data.assignedMD.name}</Td>
              <Td>
                {" "}
                <Box
                  component="img"
                  src={data.assignedMD.signatureImagePsUrl}
                  sx={{ height: "30px", width: "200px", cursor: "pointer" }}
                />
              </Td>
              <Td>
                Date:{" "}
                {ps().clearedAt ? format(ps().clearedAt, "MM/dd/yyyy") : ""}
              </Td>
            </tr>
            <tr>
              <Td>Employee's Name: {data.employee.employeeName}</Td>
              <Td>Employee's Signature: ________________</Td>
              <Td>Date: ________________</Td>
            </tr>
          </tbody>
        </Table>
        <SmallText>
          * Social Security Number required if exam includes chemical specific
          evaluation covered by section E
        </SmallText>
      </div>
    </FormContainer>
  );
};

export default PeriodicExamForm;
