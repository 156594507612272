import { LoadingButton } from "@mui/lab";
import {
  Alert,
  AlertTitle,
  Button,
  Chip,
  Drawer,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Stack } from "@mui/system";
import Grid from "@mui/system/Unstable_Grid/Grid";
import { Card, ProgressBar } from "@tremor/react";
import cogoToast from "cogo-toast";
import { format } from "date-fns";
import React from "react";
import Iconify from "src/components/iconify";
import { LoadingScreen } from "src/components/loading-screen";
import { View500 } from "src/sections/error";
import { WithAPICall } from "../utils/apiUtil";
import { POOL_SETTINGS } from "./data/pool-settings";
import { VisitRow } from "../Visit/VisitList";
import Papa from "papaparse";

const POOL_SCALE_FACTOR = 1.1;

class ProtocolPoolDetails extends React.Component {
  state = {
    isLoading: false,
    isError: false,
    isCreatingDraw: false,
    draws: [],
    drawnVisits: [],
    isLoadingVisits: false,
    selectedDrawForVisits: null,
    openPopulationDrawer: false,
    openVisitDrawer: false,
    isLoadingPopulation: false,
    visits: [],
    isLoadingDraws: false,
    population: [],
    filterPopulation: null,
  };
  async componentDidMount() {
    await this.getDraws();
    await this.getPoolDrawHelper();
    await this.getPoolDraws();
  }
  canCreatePoolDraw = () => {
    let allowed = true;
    const keys = POOL_SETTINGS.items.map((i) => i.id);
    keys.forEach((key) => {
      if (key === "canPoolDrawVisitGoIntoNextPeriod") return;
      if (!this.props.data[key] || this.props.data[key]?.length === 0)
        allowed = false;
    });
    return allowed;
  };
  getPoolDrawHelper = async () => {
    try {
      this.setState({ isLoading: true });
      let res = await this.props.apiCallPost(
        "/company-protocol/pool/getPoolDrawHelper",
        {
          poolId: this.props.protocolId,
          // TODO: dropdown for this
          effectiveYear: "2024",
        }
      );
      const recommendedSamplingForDrug = Math.ceil(
        (res.poolCanonicalDrugTestsRequiredPerPeriod /
          res.canonicalPopulationSize) *
          100 *
          POOL_SCALE_FACTOR
      );
      const recommendedSamplingForAlcohol = Math.ceil(
        (res.poolCanonicalAlcoholTestsRequiredPerPeriod /
          res.canonicalPopulationSize) *
          100 *
          POOL_SCALE_FACTOR
      );
      this.setState({
        isLoading: false,
        ...res,
        recommendedSamplingForDrug,
        recommendedSamplingForAlcohol,
      });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Draws");
      this.setState({ isLoading: false, isError: true });
    }
  };
  getDraws = async () => {
    try {
      this.setState({ isLoading: true });
      let res = await this.props.apiCallPost(
        "/company-protocol/pool/getPoolDraws",
        {
          poolId: this.props.protocolId,
        }
      );
      this.setState({ isLoading: false, draws: [] });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Loading Draws");
      this.setState({ isLoading: false, isError: true });
    }
  };
  getPoolDraws = async () => {
    this.setState({ isLoadingDraws: true });
    try {
      const res = await this.props.apiCallPost(
        "/company-protocol/pool/getPoolDraws",
        {
          poolId: this.props.protocolId,
        }
      );
      // for each draw load the population
      for (const draw of res.draws) {
        let pRes = await this.props.apiCallPost(
          "/company-protocol/pool/getEmployeesForPoolDraw",
          {
            drawId: draw._id,
          }
        );
        draw.population = pRes;
      }
      this.setState({ ...res });
    } catch (error) {
      console.error("could not get pool draw data", error);
    }
    this.setState({ isLoadingDraws: false });
  };
  createPoolDraw = async () => {
    try {
      this.setState({ isCreatingDraw: true });
      let res = await this.props.apiCallPost(
        "/company-protocol/pool/createPoolDraw",
        {
          poolId: this.props.protocolId,
          samplingFractionDrugs: this.state.recommendedSamplingForDrug / 100,
          samplingFractionAlcohol:
            this.state.recommendedSamplingForAlcohol / 100,
          effectiveYear: "2024",
        }
      );
      console.log(res);
      cogoToast.success("Draw Created");
      this.setState({ isCreatingDraw: false });
    } catch (err) {
      console.log(err);
      cogoToast.error("Error Creating Draw");
      this.setState({ isCreatingDraw: false });
    }
  };
  loadPopulation = async (drawId) => {
    if (!this.state.selectedDrawForVisits) return;
    this.setState({ isLoadingPopulation: true });
    try {
      let res = await this.props.apiCallPost(
        "/company-protocol/pool/getEmployeesForPoolDraw",
        {
          drawId,
        }
      );
      this.setState({ population: res });
    } catch (error) {
      console.error("could not get population", error);
    }
    this.setState({ isLoadingPopulation: false });
  };
  loadVisits = async (drawId) => {
    if (!drawId) return;
    try {
      let res = await this.props.apiCallPost(
        "/company-protocol/pool/getVisitsForPoolDraw",
        {
          drawId,
        }
      );
      this.setState({ drawnVisits: res, isLoadingVisits: false });
    } catch (error) {
      console.error("could not get visits", error);
    }
    this.setState({ isLoadingVisits: false });
  };
  renderCreateDrawArea = () => {
    let canonicalForPeriod = 100;
    let currentPop = 200;
    let drawnTotalSoFar = 50;
    let periods = 10;
    let periodsSoFar = 5;
    let periodsLeft = periods - periodsSoFar;
    let periodsText = periodsLeft === 1 ? "Draw" : "Draws";
    let samplesLeft = canonicalForPeriod - drawnTotalSoFar;
    let samplesLeftText = samplesLeft === 1 ? "Test" : "Tests";
    let samplePerPeriodRecommended = (samplesLeft * 1.1) / periodsLeft;
    let percentToSample =
      this.state.percentToSample ||
      Math.round((samplePerPeriodRecommended / currentPop) * 100);
    return (
      <Alert severity="info" icon={<Iconify icon="ion:dice-outline" />}>
        <AlertTitle>Create New Draw</AlertTitle>{" "}
        <Stack spacing={2}>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Canonical Size</Typography>
            <Typography variant="overline">
              {this.state.canonicalPopulationSize}
            </Typography>
          </Stack>{" "}
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Current Size</Typography>
            <Typography variant="overline">
              {this.state.currentPopulationSize}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">
              Number of Draws Required Annually
            </Typography>
            <Typography variant="overline">
              {this.state.numDrawsRequiredAnnually}
            </Typography>
          </Stack>
          <Typography variant="subtitle2">Alcohol</Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Annual Test Requirement</Typography>
            <Typography variant="overline">
              {this.state.poolCanonicalAlcoholTestsRequired}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">
              Per {this.props.data.companyProtocolFrequencyValue}{" "}
              {this.props.data.companyProtocolFrequencyUnit} Test Requirement
            </Typography>
            <Typography variant="overline">
              {this.state.poolCanonicalAlcoholTestsRequiredPerPeriod}
            </Typography>
          </Stack>
          {/* <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Annual Tests Fulfilled</Typography>
            <Typography variant="overline">
              {this.state.drawRequirementAlcohol}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Annual Tests Remaining</Typography>
            <Typography variant="overline">
              {this.state.drawRequirementAlcohol}
            </Typography>
          </Stack> */}
          <Typography variant="subtitle2">Drugs</Typography>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Annual Test Requirement</Typography>
            <Typography variant="overline">
              {this.state.poolCanonicalDrugTestsRequired}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">
              Per {this.props.data.companyProtocolFrequencyValue}{" "}
              {this.props.data.companyProtocolFrequencyUnit} Test Requirement
            </Typography>
            <Typography variant="overline">
              {this.state.poolCanonicalDrugTestsRequiredPerPeriod}
            </Typography>
          </Stack>
          {/* <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Annual Tests Fulfilled</Typography>
            <Typography variant="overline">
              {this.state.drawRequirementDrugs}
            </Typography>
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Annual Tests Remaining</Typography>
            <Typography variant="overline">
              {this.state.drawRequirementDrugs}
            </Typography>
          </Stack> */}
          {/* <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">Remaining:</Typography>
            <Typography variant="overline">
              {samplesLeft} {samplesLeftText} over {periodsLeft} {periodsText}
            </Typography>
          </Stack>{" "} */}
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">
              Recommended Sampling for Drug %
            </Typography>
            <TextField
              size="small"
              value={this.state.recommendedSamplingForDrug}
              onChange={(e) => {
                let val = parseInt(e.target.value);
                this.setState({ recommendedSamplingForDrug: val });
              }}
            />
          </Stack>
          <Stack spacing={1} direction="row" alignItems="center">
            <Typography variant="caption">
              Recommended Sampling for Alcohol %
            </Typography>
            <TextField
              size="small"
              value={this.state.recommendedSamplingForAlcohol}
              onChange={(e) => {
                let val = parseInt(e.target.value);
                this.setState({ recommendedSamplingForAlcohol: val });
              }}
            />
          </Stack>
          <LoadingButton
            disabled={
              !percentToSample || percentToSample < 1 || percentToSample > 100
            }
            variant="outlined"
            loading={this.state.isCreatingDraw}
            onClick={() => {
              this.createPoolDraw();
            }}
          >
            Create Draw
          </LoadingButton>
        </Stack>
      </Alert>
    );
  };
  renderPopulationCards = () => {
    if (!this.state.selectedDrawForVisits) return <></>;
    if (this.state.isLoadingPopulation) return <LoadingScreen />;
    const selectedDrawId = this.state.selectedDrawForVisits._id;
    const selectedDraw = this.state.draws.find((d) => d._id === selectedDrawId);
    let population = [];
    if (!this.state.filterPopulation) population = selectedDraw.population;
    if (this.state.filterPopulation === "DRUGS")
      population = selectedDraw.population.filter(
        (p) => p.taskMetadata.poolSelectDrugs
      );
    if (this.state.filterPopulation === "ALCOHOL")
      population = selectedDraw.population.filter(
        (p) => p.taskMetadata.poolSelectAlcohol
      );
    return (
      <Table sx={{ minWidth: 650 }} aria-label="employee table">
        <TableHead>
          <TableRow>
            <TableCell>Employee SSN</TableCell>
            <TableCell>Employee Name</TableCell>
            <TableCell>Employee Number</TableCell>
            <TableCell>Date of Birth</TableCell>
            <TableCell>Selected For Drug</TableCell>
            <TableCell>Selected For Alcohol</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {population.map((task, index) => (
            <TableRow key={index}>
              <TableCell>{task.employee.employeeSSN}</TableCell>
              <TableCell>
                {task.employee.employeeName}
                <IconButton
                  size="small"
                  href={`/employee/${task.employee._id}`}
                  variant="outlined"
                >
                  <Iconify icon="mdi:arrow-top-right" />
                </IconButton>
              </TableCell>
              <TableCell>{task.employee.employeeNumber}</TableCell>
              <TableCell>
                {task.employee.employeeDoB
                  ? format(
                      new Date(task.employee.employeeDoB.replaceAll("Z", "")),
                      "PP"
                    )
                  : "<No DoB on File>"}
              </TableCell>
              <TableCell>
                {task.taskMetadata.poolSelectDrugs ? "Yes" : "No"}
              </TableCell>
              <TableCell>
                {task.taskMetadata.poolSelectAlcohol ? "Yes" : "No"}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    );
  };

  renderVisitCards = () => {
    if (this.state.isLoadingVisits) return <LoadingScreen />;
    return (
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Employee</TableCell> <TableCell>Employer</TableCell>
            <TableCell>Protocol(s)</TableCell> <TableCell>Clinic</TableCell>
            <TableCell>Lab(s)</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Clearances(s)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.state.drawnVisits.map((visit, index) => (
            <VisitRow visit={visit} key={index} />
          ))}
        </TableBody>
      </Table>
    );
  };

  renderDraws = () => {
    if (this.state.isLoadingDraws) return <LoadingScreen />;
    return (
      <>
        {this.state.draws.map((draw, index) => (
          <Paper elevation={2} sx={{ mb: 2 }} key={index}>
            <Accordion>
              <AccordionSummary>
                <Stack direction="row" spacing={2} alignItems="center">
                  <Typography variant="subtitle2">Draw {index + 1}</Typography>
                  <Chip
                    variant="outlined"
                    label={`Number of unique employees ${draw.population.length}`}
                  ></Chip>
                  <Chip
                    variant="outlined"
                    label={`Drug ${(draw.nCompletedDrug / draw.sampleSizeDrugs) * 100}%`}
                  />
                  <Chip
                    variant="outlined"
                    label={`Alcohol ${(draw.nCompletedAlcohol / draw.sampleSizeAlcohol) * 100}%`}
                  />
                  <Chip
                    variant="outlined"
                    icon={<Iconify icon="lets-icons:date-fill" />}
                    label={`Drawn at 
                    ${draw.drawnAt ? format(new Date(draw.drawnAt), "PP") : ""}`}
                  />
                </Stack>
              </AccordionSummary>
              <AccordionDetails>
                <Button
                  onClick={() => {
                    this.setState(
                      {
                        openPopulationDrawer: true,
                        selectedDrawForVisits: draw,
                      },
                      () => {
                        // this.loadPopulation(draw._id);
                      }
                    );
                  }}
                  startIcon={<Iconify icon="ic:baseline-people" />}
                >
                  See Population
                </Button>
                <Button
                  onClick={() => {
                    this.setState(
                      {
                        drawnVisits: [],
                        isLoadingVisits: true,
                        openVisitDrawer: true,
                      },
                      () => {
                        this.loadVisits(draw._id);
                      }
                    );
                  }}
                  startIcon={<Iconify icon="ic:baseline-people" />}
                >
                  See Visits
                </Button>
                <div className="grid grid-cols-1 gap-4 sm:max-w-3xl sm:grid-cols-2 mt-3">
                  <Card
                    key={draw._id}
                    className="p-4 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
                    onClick={() => {
                      this.setState({
                        filterPopulation: "DRUGS",
                        openPopulationDrawer: true,
                        selectedDrawForVisits: draw,
                      });
                    }}
                  >
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                      <span className="absolute inset-0" aria-hidden={true} />
                      Sample Size Drugs
                    </p>
                    <p className="mt-3 flex items-end">
                      <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                        {draw.sampleSizeDrugs}
                      </span>
                      <span className="font-semibold text-tremor-content-subtle dark:text-dark-tremor-content-subtle">
                        /{draw.canonicalPopulationSize}
                      </span>
                    </p>
                  </Card>
                  <Card
                    key={draw._id}
                    className="p-4 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
                    onClick={() => {
                      this.setState({
                        filterPopulation: "ALCOHOL",
                        openPopulationDrawer: true,
                        selectedDrawForVisits: draw,
                      });
                    }}
                  >
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                      <span className="absolute inset-0" aria-hidden={true} />
                      Sample Size Alcohol
                    </p>
                    <p className="mt-3 flex items-end">
                      <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                        {draw.sampleSizeAlcohol}
                      </span>
                      <span className="font-semibold text-tremor-content-subtle dark:text-dark-tremor-content-subtle">
                        /{draw.canonicalPopulationSize}
                      </span>
                    </p>
                  </Card>
                  <Card
                    key={draw._id}
                    className="p-4 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
                  >
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                      <span className="absolute inset-0" aria-hidden={true} />
                      Completed Drugs
                    </p>
                    <p className="mt-3 flex items-end">
                      <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                        {draw.nCompletedDrug}
                      </span>
                      <span className="font-semibold text-tremor-content-subtle dark:text-dark-tremor-content-subtle">
                        /{draw.sampleSizeDrugs}
                      </span>
                    </p>
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                      <span>
                        {(draw.nCompletedDrug / draw.sampleSizeDrugs) * 100}%
                      </span>
                    </p>
                    <ProgressBar
                      value={(draw.nCompletedDrug / draw.sampleSizeDrugs) * 100}
                      color="teal"
                      className="mt-3"
                    />
                  </Card>
                  <Card
                    key={draw._id}
                    className="p-4 hover:bg-tremor-background-muted hover:dark:bg-dark-tremor-background-muted"
                  >
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content">
                      <span className="absolute inset-0" aria-hidden={true} />
                      Completed Alcohol
                    </p>
                    <p className="mt-3 flex items-end">
                      <span className="text-tremor-metric font-semibold text-tremor-content-strong dark:text-dark-tremor-content-strong">
                        {draw.nCompletedAlcohol}
                      </span>
                      <span className="font-semibold text-tremor-content-subtle dark:text-dark-tremor-content-subtle">
                        /{draw.sampleSizeAlcohol}
                      </span>
                    </p>
                    <p className="text-tremor-default text-tremor-content dark:text-dark-tremor-content flex items-center justify-between">
                      <span>
                        {(draw.nCompletedAlcohol / draw.sampleSizeAlcohol) *
                          100}
                        %
                      </span>
                    </p>
                    <ProgressBar
                      value={
                        (draw.nCompletedAlcohol / draw.sampleSizeAlcohol) * 100
                      }
                      color="emerald"
                      className="mt-3"
                    />
                  </Card>
                </div>
              </AccordionDetails>
            </Accordion>
          </Paper>
        ))}
      </>
    );
  };

  downloadPopulationCSV = () => {
    let population = [];
    const selectedDrawId = this.state.selectedDrawForVisits._id;
    const selectedDraw = this.state.draws.find((d) => d._id === selectedDrawId);
    if (!this.state.filterPopulation) population = selectedDraw.population;
    // if (this.state.filterPopulation === "DRUGS")
    //   population = selectedDraw.population.filter(
    //     (p) => p.taskMetadata.poolSelectDrugs
    //   );
    // if (this.state.filterPopulation === "ALCOHOL")
    //   population = selectedDraw.population.filter(
    //     (p) => p.taskMetadata.poolSelectAlcohol
    //   );
    const data = population.map((p) => {
      return {
        employeeSSN: p.employee.employeeSSN,
        employeeNumber: p.employee.employeeNumber,
        employeeName: p.employee.employeeName,
        // employeeDoB: format(new Date(p.employee.employeeDoB), "PP"),
        employeeDoB: p.employee.employeeDoB
          ? format(new Date(p.employee.employeeDoB.replaceAll("Z", "")), "PP")
          : "<No DoB on File>",
        selectedForDrugScreen: p.taskMetadata.poolSelectDrugs ? "Yes" : "No",
        selectedForAlcoholTest: p.taskMetadata.poolSelectAlcohol ? "Yes" : "No",
        taskId: p._id,
      };
    });

    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const link = document.createElement("a");
    if (link.download !== undefined) {
      const url = URL.createObjectURL(blob);
      link.setAttribute("href", url);
      link.setAttribute("download", `selected-population-drawn.csv`);
      link.style.visibility = "hidden";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    if (!this.props.data.isPool) {
      return (
        <Alert severity="warning">This tab is only applicable for pools.</Alert>
      );
    }
    if (!this.canCreatePoolDraw()) {
      return (
        <Alert severity="info">
          Please set all pool values in the Info tab to create a draw.
        </Alert>
      );
    }
    return (
      <>
        <Drawer
          open={this.state.openPopulationDrawer}
          onClose={() => {
            this.setState({ openPopulationDrawer: false });
          }}
          anchor="right"
        >
          <Stack spacing={2} sx={{ p: 3 }}>
            <Stack direction="row" spacing={1} alignItems="center">
              <Typography variant="h5">Selected Population</Typography>
              <Button
                onClick={() => this.downloadPopulationCSV()}
                variant="outlined"
                startIcon={<Iconify icon="mingcute:external-link-line" />}
              >
                Download CSV
              </Button>
            </Stack>
            {this.renderPopulationCards()}
          </Stack>
        </Drawer>
        <Drawer
          open={this.state.openVisitDrawer}
          onClose={() => {
            this.setState({ openVisitDrawer: false });
          }}
          anchor="right"
        >
          <Stack spacing={2} sx={{ p: 3 }}>
            <Typography variant="h5">Drawn Visits</Typography>
            {this.renderVisitCards()}
          </Stack>
        </Drawer>
        <Stack spacing={1}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={8}>
              {this.renderDraws()}
            </Grid>
            <Grid item xs={12} md={4} spacing={2}>
              {this.renderCreateDrawArea()}
            </Grid>
          </Grid>
        </Stack>
      </>
    );
  }
}

export default WithAPICall(ProtocolPoolDetails);
