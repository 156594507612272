import { fontSize } from "@mui/system";
import axios from "axios";
import moment from "moment-timezone";
const apiUrl = process.env.REACT_APP_API_URL;

export const fmtDob = (dob) => {
  if (!dob) return "<No DOB on file>";
  return niceDateTZ(dob.replaceAll("Z", ""));
};

export const niceDateTimeTZ = (date) => {
  return new Date(date).toLocaleTimeString(
    navigator?.languages?.[0] || "en-US",
    {
      month: "short",
      day: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    }
  );
};
export const niceDateTZ = (date) => {
  return new Date(date).toLocaleDateString(
    navigator?.languages?.[0] || "en-US",
    {
      month: "short",
      day: "2-digit",
      year: "numeric",
    }
  );
};
export const niceTimeTZ = (date) => {
  return new Date(date).toLocaleTimeString(
    navigator?.languages?.[0] || "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
    }
  );
};

export const niceDateTimeUS = (date) => {
  return new Date(date).toLocaleTimeString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
export const niceDateUS = (date) => {
  return new Date(date).toLocaleDateString("en-US", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};
export const niceTimeUS = (date) => {
  return new Date(date).toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const niceDateTime = (date) => {
  return new Date(date).toLocaleTimeString("en-IN", {
    month: "short",
    day: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
  });
};
export const niceDate = (date) => {
  return new Date(date).toLocaleDateString("en-IN", {
    month: "short",
    day: "2-digit",
    year: "numeric",
  });
};
export const niceTime = (date) => {
  return new Date(date).toLocaleTimeString("en-IN", {
    hour: "2-digit",
    minute: "2-digit",
  });
};

export const niceFileSize = (inputValue) => {
  if (!inputValue) return "";

  if (inputValue === 0) return "0 Bytes";

  const units = ["bytes", "Kb", "Mb", "Gb", "Tb", "Pb", "Eb", "Zb", "Yb"];

  const decimal = 2;

  const baseValue = 1024;

  const number = Number(inputValue);

  const index = Math.floor(Math.log(number) / Math.log(baseValue));

  const fm = `${parseFloat((number / baseValue ** index).toFixed(decimal))} ${
    units[index]
  }`;

  return fm;
};

export const isInPastDate = (date, compareDate = new Date()) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion >= 0) {
    // compareDate or Future
    return true;
  } else {
    return false;
  }
};
export const isInPastDateStrict = (date, compareDate = new Date()) => {
  // if date in question is in the past wrt compareDate return true.
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion > 0) {
    // Future
    return true;
  } else {
    return false;
  }
};

export const isInFutureDate = (date, compareDate = new Date()) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion <= 0) {
    // compareDate or Future
    return true;
  } else {
    return false;
  }
};
export const isInFutureDateStrict = (date, compareDate = new Date()) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date(compareDate);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);
  if (compareTo - dateInQuestion < 0) {
    // Future
    return true;
  } else {
    return false;
  }
};

export const isDateToday = (date) => {
  let dateInQuestion = new Date(date);
  let compareTo = new Date();
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion === 0) {
    return true;
  } else {
    return false;
  }
};
export const areTwoDatesSame = (d1, d2) => {
  let dateInQuestion = new Date(d1);
  let compareTo = new Date(d2);
  compareTo.setHours(0, 0, 0, 0);
  dateInQuestion.setHours(0, 0, 0, 0);

  if (compareTo - dateInQuestion === 0) {
    return true;
  } else {
    return false;
  }
};

export const formatTime = (seconds) => {
  if (typeof seconds !== "number" || isNaN(seconds)) {
    return "";
  }
  const days = Math.floor(seconds / 86400);
  const hours = Math.floor((seconds % 86400) / 3600);
  const minutes = Math.floor((seconds % 3600) / 60);

  let timeStr = [];
  if (days > 0) {
    timeStr.push(`${days} Day${days > 1 ? "s" : ""}`);
  }
  if (hours > 0) {
    timeStr.push(`${hours} Hour${hours > 1 ? "s" : ""}`);
  }
  if (minutes > 0) {
    timeStr.push(`${minutes} Min${minutes > 1 ? "s" : ""}`);
  }

  return timeStr.join(" ");
};

export const stringAvatar = (name) => {
  function stringToColor(string) {
    let hash = 0;
    let i;

    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }

    return color;
  }
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: 24,
      height: 24,
      fontSize: "small",
    },
    children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
  };
};

export const apiCallPostUnuath = async (path, datum) => {
  const resp = await axios.post(apiUrl + path, datum, {
    credentials: "include",
    // headers: { Authorization: accessToken },
  });
  return resp.data;
};

export const maskSSN = (ssn) => {
  if (!ssn) return "SSN not on file";
  // strip all dash and space
  ssn = ssn.replace(/-|\s/g, "");
  // mask all but last 4 digits
  return ssn.replace(/.(?=.{4})/g, "*");
};

const timezoneAbbrToIANA = {
  ACDT: "Australia/Adelaide",
  ACST: "Australia/Darwin",
  ACT: "Australia/Darwin",
  ADT: "America/Halifax",
  AEDT: "Australia/Sydney",
  AEST: "Australia/Brisbane",
  AFT: "Asia/Kabul",
  AKDT: "America/Anchorage",
  AKST: "America/Anchorage",
  ALMT: "Asia/Almaty",
  AMST: "America/Campo_Grande",
  AMT: "America/Cuiaba",
  ART: "America/Argentina/Buenos_Aires",
  AST: "America/Halifax",
  AWDT: "Australia/Perth",
  AWST: "Australia/Perth",
  AZOST: "Atlantic/Azores",
  AZT: "Asia/Baku",
  BDT: "Asia/Dhaka",
  BIOT: "Indian/Chagos",
  BIT: "Pacific/Enderbury",
  BOT: "America/La_Paz",
  BRST: "America/Sao_Paulo",
  BRT: "America/Sao_Paulo",
  BST: "Europe/London",
  BTT: "Asia/Thimphu",
  CAT: "Africa/Harare",
  CCT: "Indian/Cocos",
  CDT: "America/Chicago",
  CEST: "Europe/Paris",
  CET: "Europe/Paris",
  CHADT: "Pacific/Chatham",
  CHAST: "Pacific/Chatham",
  CHOT: "Asia/Choibalsan",
  CHST: "Pacific/Guam",
  CHUT: "Pacific/Chuuk",
  CIST: "Pacific/Rarotonga",
  CKT: "Pacific/Rarotonga",
  CLST: "America/Santiago",
  CLT: "America/Santiago",
  COST: "America/Bogota",
  COT: "America/Bogota",
  CST: "America/Chicago",
  CT: "Asia/Shanghai",
  CVT: "Atlantic/Cape_Verde",
  CWST: "Australia/Eucla",
  CXT: "Indian/Christmas",
  DAVT: "Antarctica/Davis",
  DDUT: "Antarctica/DumontDUrville",
  DFT: "Europe/Paris",
  EASST: "Pacific/Easter",
  EAST: "Pacific/Easter",
  EAT: "Africa/Nairobi",
  ECT: "America/Guayaquil",
  EDT: "America/New_York",
  EEST: "Europe/Istanbul",
  EET: "Europe/Istanbul",
  EGST: "Atlantic/Reykjavik",
  EGT: "Atlantic/Reykjavik",
  EIT: "Asia/Jayapura",
  EST: "America/New_York",
  FET: "Europe/Kaliningrad",
  FJT: "Pacific/Fiji",
  FKST: "Atlantic/Stanley",
  FKT: "Atlantic/Stanley",
  FNT: "America/Noronha",
  GALT: "Pacific/Galapagos",
  GAMT: "Pacific/Gambier",
  GET: "Asia/Tbilisi",
  GFT: "America/Cayenne",
  GILT: "Pacific/Tarawa",
  GMT: "Europe/London",
  GST: "Asia/Dubai",
  GYT: "America/Guyana",
  HADT: "Pacific/Honolulu",
  HAST: "Pacific/Honolulu",
  HKT: "Asia/Hong_Kong",
  HMT: "Indian/Chagos",
  HOVT: "Asia/Hovd",
  ICT: "Asia/Bangkok",
  IDT: "Asia/Jerusalem",
  IOT: "Indian/Chagos",
  IRDT: "Asia/Tehran",
  IRKT: "Asia/Irkutsk",
  IRST: "Asia/Tehran",
  IST: "Asia/Kolkata",
  JST: "Asia/Tokyo",
  KGT: "Asia/Bishkek",
  KOST: "Pacific/Kosrae",
  KRAT: "Asia/Krasnoyarsk",
  KST: "Asia/Seoul",
  LHDT: "Australia/Lord_Howe",
  LHST: "Australia/Lord_Howe",
  LINT: "Pacific/Kiritimati",
  MAGT: "Asia/Magadan",
  MART: "Pacific/Marquesas",
  MAWT: "Antarctica/Mawson",
  MDT: "America/Denver",
  MHT: "Pacific/Majuro",
  MIST: "Antarctica/Macquarie",
  MIT: "Pacific/Apia",
  MMT: "Asia/Yangon",
  MSK: "Europe/Moscow",
  MST: "America/Denver",
  MUT: "Indian/Mauritius",
  MVT: "Indian/Maldives",
  MYT: "Asia/Kuala_Lumpur",
  NCT: "Pacific/Noumea",
  NDT: "America/St_Johns",
  NFT: "Pacific/Norfolk",
  NPT: "Asia/Kathmandu",
  NST: "America/St_Johns",
  NT: "America/St_Johns",
  NUT: "Pacific/Niue",
  NZDT: "Pacific/Auckland",
  NZST: "Pacific/Auckland",
  OMST: "Asia/Omsk",
  ORAT: "Asia/Oral",
  PDT: "America/Los_Angeles",
  PET: "America/Lima",
  PETT: "Asia/Kamchatka",
  PGT: "Pacific/Port_Moresby",
  PHOT: "Pacific/Enderbury",
  PHT: "Asia/Manila",
  PKT: "Asia/Karachi",
  PMDT: "America/Miquelon",
  PMST: "America/Miquelon",
  PONT: "Pacific/Pohnpei",
  PST: "America/Los_Angeles",
  PWT: "Pacific/Palau",
  PYST: "America/Asuncion",
  PYT: "America/Asuncion",
  RET: "Indian/Reunion",
  ROTT: "Antarctica/Rothera",
  SAKT: "Asia/Sakhalin",
  SAMT: "Europe/Samara",
  SAST: "Africa/Johannesburg",
  SBT: "Pacific/Guadalcanal",
  SCT: "Indian/Mahe",
  SGT: "Asia/Singapore",
  SLST: "Asia/Colombo",
  SRET: "Asia/Srednekolymsk",
  SRT: "America/Paramaribo",
  SST: "Pacific/Midway",
  SYOT: "Antarctica/Syowa",
  TAHT: "Pacific/Tahiti",
  TFT: "Indian/Kerguelen",
  TJT: "Asia/Dushanbe",
  TKT: "Pacific/Fakaofo",
  TLT: "Asia/Dili",
  TMT: "Asia/Ashgabat",
  TRT: "Europe/Istanbul",
  TOT: "Pacific/Tongatapu",
  TVT: "Pacific/Funafuti",
  ULAT: "Asia/Ulaanbaatar",
  UTC: "Etc/UTC",
  UYST: "America/Montevideo",
  UYT: "America/Montevideo",
  VET: "America/Caracas",
  VLAT: "Asia/Vladivostok",
  VOLT: "Europe/Volgograd",
  VOST: "Antarctica/Vostok",
  VUT: "Pacific/Efate",
  WAKT: "Pacific/Wake",
  WAST: "Africa/Windhoek",
  WAT: "Africa/Lagos",
  WEST: "Europe/Lisbon",
  WET: "Europe/Lisbon",
  WFT: "Pacific/Wallis",
  WGST: "America/Godthab",
  WGT: "America/Godthab",
  WIB: "Asia/Jakarta",
  WIT: "Asia/Jayapura",
  WITA: "Asia/Makassar",
  WST: "Australia/Perth",
  YAKT: "Asia/Yakutsk",
  YEKT: "Asia/Yekaterinburg",
};
export const formattedTZTime = (date, tz) => {
  if (!tz) {
    return niceDateTimeUS(date);
  }
  const formattedTime = moment
    .utc(date)
    .tz(timezoneAbbrToIANA[tz])
    .format("DD MMM YYYY, hh:mm a z");
  return formattedTime;
};
