export const SERVICE_DATA_TYPE_OPTIONS = [
  "Alcohol Test",
  "ASR Form",
  "Kraus Weber Back Assessment",
  "BBP Exposure Screening",
  "Beryllium Results",
  "Cardiac Stress Test",
  "CCF - NonDOT - Urine",
  "CCF - DOT - Urine",
  "CCF - NonDOT - Hair",
  "CCF - DOT - Oral Fluids",
  "CCF - NonDOT - Oral Fluids",
  "Coast Guard Exam (CG-719k)",
  "Dental Exam",
  "DOT Exam",
  "DOT Card",
  "Drug Screen Instant Limits",
  "Drug Screen Lab Results",
  "Epworth Sleepiness Scale",
  "Fit for Duty",
  "Functional Capacity Evaluation",
  "Height and Weight",
  "Hemoccult",
  "Immunization Summary/History",
  "Vaccine Declination Form",
  "Musculoskeletal Assessment",
  "OSHA Respirator Questionnaire",
  "Norwegian Seafarer Certification",
  "OGUK Exam Certification",
  "Post-Accident Questionnaire",
  "PEPCAS",
  "Respirator Fit Test",
  "TB Questionnaire",
  "Silica Medical Release",
  "TB Blood Result",
  "TB Mantoux",
  "Vision",
];

export const TYPE_OF_DOCUMENT_OPTIONS = [
  "PFT",
  "LAB_REPORT",
  "CHAIN_OF_CUSTODY",

  "CXR",
  "ECG",
  "BAT",

  "AUDIOGRAM",
  "QUESTIONNAIRE",
  "VACCINATION",

  "URINANALYSIS",

  "DOT_FORM",
  "DOT_CARD",
  "WHISPER_TEST",

  "VISION",
  "VITALS",

  "RESP_FIT_TEST",
];

export const AI_DATA_TAGGER = [
  "PFT",
  "LAB_REPORT",
  "CHAIN_OF_CUSTODY",

  "CXR",
  "ECG",
  "BAT",

  "AUDIOGRAM",
  "QUESTIONNAIRE",
  "VACCINATION",

  "URINANALYSIS",

  "DOT_CARD",
  // "DOT_FORM",
  "WHISPER_TEST",

  "VITALS",
  "VISION",

  "RESP_FIT_TEST",
];
