/* eslint-disable no-redeclare */
import React from "react";
import { WithAPICall } from "../utils/apiUtil";
import {
  Alert,
  AlertTitle,
  Card,
  Chip,
  LinearProgress,
  Paper,
  Button,
  Grid,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Box,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import { Stack } from "@mui/system";
import _, { groupBy, debounce, flatten } from "lodash";
import { View500 } from "src/sections/error";
import { niceDate, niceDateTime, niceDateTZ } from "../utils/fn";
import Iconify from "src/components/iconify";
import { LoadingButton } from "@mui/lab";
import cogoToast from "cogo-toast";
import axios from "axios";
import { format } from "date-fns";
import TextareaAutosize from "src/components/text-area-autosize";
import { DOCUMENT_GENERATOR_URL } from "../../config-global";
import generateBarcode from "./utils/generate-ff-barcode";
import TokenContext from "src/TokenContext";
import AnimatedCheck from "src/components/animated-check";
import {
  DateTimePicker,
  LocalizationProvider,
  TimeField,
} from "@mui/x-date-pickers-pro";
import moment from "moment";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { AdapterMoment } from "@mui/x-date-pickers-pro/AdapterMoment";

function substituteVariables(template, variables) {
  return template.replace(/{{\s*([^}]+)\s*}}/g, (_, key) => {
    return variables[key.trim()] || "";
  });
}

class VisitReviewSummarySchedule extends React.Component {
  static contextType = TokenContext;

  state = {
    isLoading: true,
    isError: false,
    serviceDetails: [],
    isSaving: false,
    isDownloadingAuthLetter: false,
    isERVisitScheduledModalOpen: false,
    suppressEEAuthzEmail: false,
    suppressProviderAuthzEmail: false,
  };
  componentDidMount() {
    this.setState(
      {
        ...this.props.visit,
        isLoading: true,
      },
      () => {
        this.getServiceDetails();
      }
    );
  }
  updateVisitScalars = async (arr) => {
    try {
      let res = await this.props.apiCallPost("/visit/updateVisitScalars", {
        visitId: this.props.visit._id,
        uops: arr,
      });
    } catch (err) {
      cogoToast.error("Error Saving Visit Data");
      console.log(err);
      this.setState({
        isSaving: false,
      });
    }
  };
  getAuthorizationLetterEmailTemplate = async (templateId) => {
    // const templateId = "665d6edda20ebc966317fdd7";
    try {
      const res = await this.props.apiCallPost("/email/fetchTemplate", {
        templateId,
      });
      return res;
    } catch (error) {
      console.error(error);
    }
  };
  // getAuthorizationLetters = async (psls) => {
  //   this.setState({ isDownloadingAuthLetter: true });
  //   const psl = psls[0];
  //   const data = {
  //     date: format(new Date(), "PP"),
  //     encounterNumber: this.state._id,
  //     employeeName: this.state.employee.employeeName,
  //     companyName: this.state.company.companyName,
  //     apptDate: psl.scheduledOnDate
  //       ? format(new Date(psl.scheduledOnDate), "PP")
  //       : "Date not selected",
  //     apptTime: psl.scheduledTime
  //       ? format(new Date(psl.scheduledTime), "pp")
  //       : "Time not selected",
  //     site: psl.providerObj.providerName,
  //     clientSvcRep: "",
  //     clinicName: psl.providerObj.providerName,
  //     address: psl.providerObj.providerAddress,
  //     phone: psl.providerObj.providerPhone,
  //     email: psl.providerObj.providerEmail,
  //     fax: psl.providerObj.providerFax,
  //     table: {
  //       headers: ["Service", "Instructions"],
  //       data: [],
  //     },
  //   };
  //   psls.forEach(async (psl) => {
  //     data.table.data.push([psl.pslName, ""]);
  //   });

  //   const response = await axios.post(
  //     DOCUMENT_GENERATOR_URL + "/api/templater/template",
  //     {
  //       fileId: "1bmcdSWFObp2Si88pDmJi0Lv93t96zkXCjmYH4K2m73U",
  //       data,
  //       convertTo: "pdf",
  //     },
  //     {
  //       responseType: "blob",
  //     }
  //   );

  //   // Create object URL for the blob
  //   const url = URL.createObjectURL(response.data);

  //   // Automatically trigger download
  //   const a = document.createElement("a");
  //   a.href = url;
  //   a.download = `${data.clinicName}_${data.employeeName}.pdf`;
  //   document.body.appendChild(a);
  //   a.click();
  //   document.body.removeChild(a);
  //   this.setState({ isDownloadingAuthLetter: false });
  // };
  // sendAuthorizationLetterFaxForClinic = async (psls) => {
  //   this.setState({ isDownloadingAuthLetter: true });
  //   const psl = psls[0];
  //   const faxNumber = psl.providerObj.providerFax;
  //   if (!faxNumber) {
  //     cogoToast.error("Provider does not have a fax number added.");
  //     this.setState({ isDownloadingAuthLetter: false });
  //     return;
  //   } else {
  //     const data = {
  //       date: format(new Date(), "PP"),
  //       encounterNumber: this.state._id,
  //       employeeName: this.state.employee.employeeName,
  //       employeeNumber: this.state.employee.employeeNumber || "Not Provided",
  //       employeeDoB: this.state.employee.employeeDoB || "Not Provided",
  //       comments: this.props.visit.noteToClinic
  //         ? this.props.visit.noteToClinic[psl.providerId] ||
  //           "No Comments Provided"
  //         : "No Comments Provided",
  //       companyName: this.state.company.companyName,
  //       apptDate: format(new Date(psl.scheduledOnDate), "PP"),
  //       apptTime: format(new Date(psl.scheduledTime), "pp"),
  //       site: psl.providerObj.providerName,
  //       clientSvcRep: "",
  //       clinicName: psl.providerObj.providerName,
  //       address: psl.providerObj.providerAddress,
  //       phone: psl.providerObj.providerPhone,
  //       email: psl.providerObj.providerEmail,
  //       fax: psl.providerObj.providerFax,
  //       services: [],
  //     };
  //     psls.forEach((psl) => {
  //       data.services.push({
  //         name: psl.pslName,
  //       });
  //     });
  //     try {
  //       const res = await this.props.apiCallPost("/email/auth-letter/fax", {
  //         documentData: data,
  //         fileId: "1gdyvxgpAO-ALOxa6F1hA16MTCd0Tixs2D4_aB2_jp80",
  //         faxNumber,
  //       });
  //       this.setState({ isDownloadingAuthLetter: false });
  //       return res;
  //     } catch (error) {
  //       console.error("could not send fax", error);
  //     }
  //   }

  //   this.setState({ isDownloadingAuthLetter: false });
  // };
  sendAuthorizationLetterEmailForClinic = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const emailTemplate = await this.getAuthorizationLetterEmailTemplate(
      "665d7dd1a20ebc96631800e8"
    );
    if (!emailTemplate) {
      cogoToast.error("No email template found for auth letter.");
      this.setState({ isDownloadingAuthLetter: false });
      return;
    }
    const emailHtml = emailTemplate.html;
    const emailBody = substituteVariables(emailHtml, {
      employeeName: this.state.employee.employeeName,
      providerName: this.state.company.companyName,
    });
    const psl = psls[0];
    const emailId = psl.providerObj.providerEmail;
    if (!emailId) {
      cogoToast.error("Provider does not have a email added.");
      this.setState({ isDownloadingAuthLetter: false });
      return null;
    }
    const data = {
      date: format(new Date(), "PP"),
      encounterNumber: this.state._id,
      employeeName: this.state.employee.employeeName,
      employeeNumber: this.state.employee.employeeNumber || "Not Provided",
      employeeDoB: this.state.employee.employeeDoB || "Not Provided",
      comments: this.props.visit.noteToClinic
        ? this.props.visit.noteToClinic[psl.providerId] ||
          "No Comments Provided"
        : "No Comments Provided",
      companyName: this.state.company.companyName,
      apptDate: format(new Date(psl.scheduledOnDate), "PP"),
      apptTime: format(new Date(psl.scheduledTime), "pp"),
      site: psl.providerObj.providerName,
      clientSvcRep: "",
      clinicName: psl.providerObj.providerName,
      address: psl.providerObj.providerAddress,
      phone: psl.providerObj.providerPhone,
      email: psl.providerObj.providerEmail,
      fax: psl.providerObj.providerFax,
      services: [],
    };
    psls.forEach((psl) => {
      data.services.push({
        name: psl.pslName,
      });
    });
    try {
      const res = await this.props.apiCallPost("/email/auth-letter/email", {
        documentData: data,
        fileId: "1gdyvxgpAO-ALOxa6F1hA16MTCd0Tixs2D4_aB2_jp80",
        emailIds: [emailId],
        body: emailBody,
      });
      this.setState({ isDownloadingAuthLetter: false });

      return res;
    } catch (error) {
      console.error("could not send fax", error);
    }

    this.setState({ isDownloadingAuthLetter: false });
  };
  sendAuthorizationLetterEmailForEE = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const emailTemplate = await this.getAuthorizationLetterEmailTemplate(
      "665d6edda20ebc966317fdd7"
    );
    if (!emailTemplate) {
      cogoToast.error("No email template found for auth letter.");
      this.setState({ isDownloadingAuthLetter: false });
      return;
    }
    const emailHtml = emailTemplate.html;
    const emailBody = substituteVariables(emailHtml, {
      employeeName: this.state.employee.employeeName,
      companyName: this.state.company.companyName,
    });
    const psl = psls[0];
    const emailIds = [];
    const emailId = psl.providerObj.providerEmail;
    if (this.props.visit.employee.employeePersonalEmail) {
      emailIds.push(this.props.visit.employee.employeePersonalEmail);
    }
    if (this.props.visit.employee.employeeOfficeEmail) {
      emailIds.push(this.props.visit.employee.employeeOfficeEmail);
    }
    if (emailIds.length === 0) {
      cogoToast.error("Employee does not have an email added.");
      this.setState({ isDownloadingAuthLetter: false });
      return null;
    } else {
      const data = {
        date: format(new Date(), "PP"),
        encounterNumber: this.state._id,
        employeeName: this.state.employee.employeeName,
        employeeNumber: this.state.employee.employeeNumber || "Not Provided",
        employeeDoB: this.state.employee.employeeDoB || "Not Provided",
        comments: this.props.visit.noteToClinic
          ? this.props.visit.noteToClinic[psl.providerId] ||
            "No Comments Provided"
          : "No Comments Provided",
        companyName: this.state.company.companyName,
        apptDate: format(new Date(psl.scheduledOnDate), "PP"),
        apptTime: format(new Date(psl.scheduledTime), "pp"),
        site: psl.providerObj.providerName,
        clientSvcRep: "",
        clinicName: psl.providerObj.providerName,
        address: psl.providerObj.providerAddress,
        phone: psl.providerObj.providerPhone,
        email: psl.providerObj.providerEmail,
        fax: psl.providerObj.providerFax,
        services: [],
        table: {
          headers: ["Service", "Instructions"],
          data: [],
        },
      };
      psls.forEach((psl) => {
        data.services.push({
          name: psl.pslName,
        });
        data.table.data.push([psl.pslName, ""]);
      });
      try {
        const res = await this.props.apiCallPost("/email/auth-letter/email", {
          documentData: data,
          fileId: "1bmcdSWFObp2Si88pDmJi0Lv93t96zkXCjmYH4K2m73U",
          emailIds,
          body: emailBody,
        });
        this.setState({ isDownloadingAuthLetter: false });
        return res;
      } catch (error) {
        console.error("could not send fax", error);
      }
    }

    this.setState({ isDownloadingAuthLetter: false });
  };
  getAuthorizationLettersForClinic = async (psls) => {
    this.setState({ isDownloadingAuthLetter: true });
    const psl = psls[0];

    const data = {
      date: format(new Date(), "PP"),
      encounterNumber: this.state._id,
      employeeName: this.state.employee.employeeName,
      employeeNumber: this.state.employee.employeeNumber || "Not Provided",
      employeeDoB: this.state.employee.employeeDoB || "Not Provided",
      comments: this.props.visit.noteToClinic
        ? this.props.visit.noteToClinic[psl.providerId] ||
          "No Comments Provided"
        : "No Comments Provided",
      companyName: this.state.company.companyName,
      apptDate: format(new Date(psl.scheduledOnDate), "PP"),
      apptTime: format(new Date(psl.scheduledTime), "pp"),
      site: psl.providerObj.providerName,
      clientSvcRep: "",
      clinicName: psl.providerObj.providerName,
      address: psl.providerObj.providerAddress,
      phone: psl.providerObj.providerPhone,
      email: psl.providerObj.providerEmail,
      fax: psl.providerObj.providerFax,
      table: {
        headers: ["Service", "Instructions"],
        data: [],
      },
      services: [],
    };
    psls.forEach(async (psl) => {
      // @ts-ignore
      data.table.data.push([psl.pslName, ""]);
      data.services.push({ name: psl.pslName });
    });
    let url = null;
    if (psl.providerObj.isFormFox) {
      // get formfox order details
      try {
        const res = await this.props.apiCallPost(
          "/visit/formfox/getFormFoxOrdersForProvider",
          {
            visitId: this.state._id,
            providerId: psl.providerId,
          }
        );
        // for each unique reference test id generate an authorization letter
        // for each provider, there will be only 1 formfox reference test id

        const formFoxOrders = flatten(res);
        console.log({ formFoxOrders });
        const referenceTestId = formFoxOrders[0].referenceTestId;
        // get reference test id for this psl
        const barcodeBase64 = generateBarcode(referenceTestId);
        const response = await axios.post(
          DOCUMENT_GENERATOR_URL + `/api/templater/template-with-image`,
          {
            fileId: "1oJMQpnYfGfVEEx6Ibv4sqpoFBpIPS8LLyOKwpQX2E-M",
            data,
            convertTo: "pdf",
            images: {
              barcode: barcodeBase64,
            },
          },
          {
            responseType: "blob",
          }
        );
        url = URL.createObjectURL(response.data);
      } catch (error) {
        console.error("could not get formfox order", error);
      }
    } else {
      const response = await axios.post(
        DOCUMENT_GENERATOR_URL + "/api/templater/template",
        {
          fileId: "1gdyvxgpAO-ALOxa6F1hA16MTCd0Tixs2D4_aB2_jp80",
          data,
          convertTo: "pdf",
        },
        {
          responseType: "blob",
        }
      );
      // Create object URL for the blob
      url = URL.createObjectURL(response.data);
    }

    // Automatically trigger download
    const a = document.createElement("a");
    a.href = url;
    a.download = `${data.clinicName}_${data.employeeName}.pdf`;
    console.log("download tag", a);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    this.setState({ isDownloadingAuthLetter: false });
  };
  getServiceDetails = async () => {
    try {
      let res = this.props.visit.servicesInfo;
      this.setState({
        isLoading: false,
        isError: false,
        serviceDetails: res,
      });
    } catch (err) {
      console.log(err);
      this.setState({
        isError: true,
        isLoading: false,
      });
    }
  };

  testFF = async () => {
    try {
      let res = await this.props.apiCallPost("/visit/testFF", {
        visitId: this.props.visit._id,
      });
      console.log(res);
    } catch (err) {
      console.log(err);
    }
  };

  pushToEventLog = async () => {
    if (!this.props.visit.providerEncounterOnTime) {
      cogoToast.error(
        "Please select a date and time to schedule the visit for."
      );
      return;
    }
    this.setState({ isSaving: true });
    let notes = `${niceDateTime(this.props.visit.providerEncounterOnTime)}`;
    if (this.props.visit.provider) {
      notes = `${notes}: ${this.props.visit.provider.providerName}`;
    }

    if (this.props.visit.servicesInfo.length) {
      const namess = this.props.visit.servicesInfo.map((x) => x.serviceName);
      notes = `${notes} - ${namess.join(", ")}`;
    }

    let payload = {
      visitId: this.props.visit._id,
      header: "Visit Scheduled",
      notes,
      creator: "User",
      statusTo: "SCHEDULED",
    };
    try {
      let res = await this.props.apiCallPost(
        "/visit/events/createNewVisitEvent",
        payload
      );
      this.setState({ isSaving: false, visitStatus: "SCHEDULED" }, () => {
        if (!this.context.currentUser.isExternal) {
          this.props.onSetToScheduled();
        }
      });
      cogoToast.success("Event Logged");
    } catch (err) {
      cogoToast.error("Error Scheduling");
      console.log(err);
    }
    this.setState({ isSaving: false, isERVisitScheduledModalOpen: true });
  };
  renderEmployeeInformation = () => {
    let eeName = this.props.visit.employee.employeeName;
    let erName = this.props.visit.company.companyName;
    const provider = this.props.visit.provider;
    const providerPSLs = this.state.providerPSLs;

    const handleEmailAuthorizationLetter = async (psls) => {
      let payload = {
        visitId: this.props.visit._id,
        header: `Authorization letter emailed to Employee`,
        notes: "",
        creator: "User",
      };
      const res = await this.sendAuthorizationLetterEmailForEE(psls);
      if (res) {
        await this.addNewEvent(payload);
      }
    };

    return (
      <Stack spacing={1}>
        <Typography variant="overline">Employee Details</Typography>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Employee Name</TableCell>
              <TableCell>Employer Name</TableCell>
              <TableCell>Employee Address</TableCell>
              <TableCell>Employee Phone</TableCell>
              <TableCell>Visit Date & Time</TableCell>
              {/* <TableCell>Authorization Letter</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle2">{eeName}</Typography>
                  <Typography variant="caption">
                    SSN: {this.props.visit.employee.employeeSSN || "NA"}
                  </Typography>
                  <Typography variant="caption">
                    Date of Birth:{" "}
                    {this.props.visit.employee.employeeDoB
                      ? format(
                          new Date(this.props.visit.employee.employeeDoB),
                          "P"
                        )
                      : "NA"}
                  </Typography>
                  <Typography variant="caption">
                    Sex: {this.props.visit.employee.employeeSex || "NA"}
                  </Typography>
                  <Typography variant="caption">
                    EID: {this.props.visit.employee.employeeNumber || "NA"}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>{erName}</TableCell>
              <TableCell>
                <Stack spacing={0}>
                  <Typography variant="caption">
                    {`${this.props.visit.employeeAddress || this.props.visit.employee.employeeAddress || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employeeAddressQualifier || this.props.visit.employee.employeeAddressQualifier || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employeeCity || this.props.visit.employee.employeeCity || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employeeState || this.props.visit.employee.employeeState || ""}`}
                  </Typography>
                  <Typography variant="caption">
                    {`${this.props.visit.employeePostalCode || this.props.visit.employee.employeePostalCode || ""}`}
                  </Typography>
                </Stack>
              </TableCell>
              <TableCell>
                <Button
                  startIcon={<Iconify icon="ic:baseline-phone" />}
                  href={`tel:${this.props.visit.employee.employeePersonalPhone}`}
                  variant="outlined"
                  size="small"
                >
                  {this.state.employee.employeePersonalPhone}
                </Button>
              </TableCell>
              <TableCell>
                <Stack spacing={1}>
                  {this.context.currentUser.isExternal && (
                    <Alert severity="info" variant="outlined">
                      Please call the clinic before selecting a time.
                    </Alert>
                  )}
                  <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    {!["REQUESTED", "OUTREACH_FOR_SCHEDULING"].includes(
                      this.props.visit.visitStatus
                    ) ? (
                      <Typography variant="overline">
                        {niceDateTZ(
                          this.state?.providerEncounterOnTime?.replaceAll(
                            "Z",
                            ""
                          )
                        )}
                        {/* {this.state.providerEncounterOnTime.toString()} */}
                      </Typography>
                    ) : (
                      <LocalizationProvider dateAdapter={AdapterMoment}>
                        <DateTimePicker
                          value={
                            this.state.providerEncounterOnTime
                              ? moment(this.state.providerEncounterOnTime)
                              : null
                          }
                          onAccept={(newValue) => {
                            this.setState(
                              {
                                providerEncounterOnTime: newValue,
                              },
                              () => {
                                this.props.onFreezeTime(newValue);
                              }
                            );
                          }}
                          onChange={(newValue) => {
                            this.setState(
                              {
                                providerEncounterOnTime: newValue,
                              },
                              () => {
                                this.props.onFreezeTime(newValue);
                              }
                            );
                          }}
                        />
                      </LocalizationProvider>
                    )}
                    {/* <Typography variant="overline">
                      {" "}
                      {provider?.providerReadableTimezone || "<No TZ>"}
                    </Typography> */}
                  </Stack>
                </Stack>
              </TableCell>
              {/* <TableCell>
                <Stack spacing={1}>
                  {providerPSLs.map((psl, idx) => {
                    const providerName = provider.providerName;
                    return (
                      <Stack
                        direction="row"
                        spacing={1}
                        key={idx}
                        alignItems="center"
                      >
                        <Typography variant="overline">
                          {providerName}
                        </Typography>
                        <Tooltip title="Download Authorization Letter">
                          <LoadingButton
                            variant="outlined"
                            size="small"
                            loading={this.state.isDownloadingAuthLetter}
                            onClick={() => {
                              this.getAuthorizationLetters(providerPSLs);
                            }}
                          >
                            <Iconify icon="material-symbols:download" />
                          </LoadingButton>
                        </Tooltip>

                        {!["OUTREACH_FOR_SCHEDULING", "REQUESTED"].includes(
                          this.props.visit.visitStatus
                        ) &&
                          !this.context.currentUser.isExternal && (
                            <Tooltip title="Email authorization letter to employee">
                              <LoadingButton
                                variant="outlined"
                                size="small"
                                loading={this.state.isDownloadingAuthLetter}
                                onClick={() => {
                                  handleEmailAuthorizationLetter(providerPSLs);
                                }}
                              >
                                <Iconify icon="ic:baseline-email" />
                              </LoadingButton>
                            </Tooltip>
                          )}
                      </Stack>
                    );
                  })}
                </Stack>
              </TableCell> */}
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
  };
  renderChosenProtocols = () => {
    let bp = this.props.visit.bookedProtocolIds;
    let bs = this.props.visit.bookedServices;
    let cp = this.props.visit.companyProtocols;
    let sd = this.state.serviceDetails;

    if (!bp || !bp.length) {
      return <Alert severity="warning">No protocols chosen</Alert>;
    }
    return (
      <Stack spacing={2}>
        <Typography variant="subtitle1">Protocols</Typography>
        <Stack spacing={1}>
          {bp.map((p, index) => {
            let pId = p;
            let whichCp = cp.find((c) => c._id === pId);
            let cpName = whichCp?.companyProtocolName || "Unknown Protocol";
            let cpType = whichCp?.companyProtocolType || "Unknown Type";
            let cpServices = whichCp.masterServices;
            let bookedServices = _.filter(
              bs,
              (b) => b.parentProtocolId === pId
            ).map((x) => x.serviceId);

            let servicesArr = [];

            for (var i = 0; i < bookedServices.length; i++) {
              let sId = bookedServices[i];

              let whichSd = _.find(sd, (s) => s._id === sId);

              let isHandledByWorkCare = whichSd?.isHandledByWorkCare;

              if (isHandledByWorkCare) {
                servicesArr.push({
                  serviceName: whichSd?.serviceName,
                  serviceId: sId,
                  isHandledByWorkCare: true,
                });
              } else {
                // find the type, "Clinic" or "Lab"

                let serviceRequiresProviderType =
                  whichSd?.serviceRequiresProviderType;

                if (serviceRequiresProviderType === "Clinic") {
                  let clinicPSLs = this.props.visit.clinicPSLs;
                  let whichPSL = _.find(clinicPSLs, (c) =>
                    c.masterServices.includes(sId)
                  );
                  if (!whichPSL) {
                    servicesArr.push({
                      serviceName: whichSd.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Clinic",
                      clinicName: null,
                      clinicId: null,
                      pslName: null,
                    });
                  } else {
                    servicesArr.push({
                      serviceName: whichSd?.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Clinic",
                      clinicName: whichPSL.providerName,
                      clinicId: whichPSL.providerId,
                      pslName: whichPSL.pslName,
                      scheduledTime: whichPSL.scheduledTime,
                    });
                  }
                } else if (serviceRequiresProviderType === "Lab") {
                  let labPSLs = this.props.visit.labPSLs;
                  let whichPSL = _.find(labPSLs, (c) =>
                    c.masterServices.includes(sId)
                  );
                  if (!whichPSL) {
                    servicesArr.push({
                      serviceName: whichSd?.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Lab",
                      clinicName: null,
                      clinicId: null,
                      pslName: null,
                    });
                  } else {
                    servicesArr.push({
                      serviceName: whichSd?.serviceName,
                      serviceId: sId,
                      isHandledByWorkCare: false,
                      serviceRequiresProviderType: "Lab",
                      clinicName: whichPSL.providerName,
                      clinicId: whichPSL.providerId,
                      pslName: whichPSL.pslName,
                      collectionAt: whichPSL.collectingClinicName || null,
                    });
                  }
                }
              }
            }

            return (
              <Card
                key={index}
                sx={{
                  p: 2,
                  bgcolor: "bg.neutral",
                  boxShadow: 2,
                }}
              >
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="overline">{cpName}</Typography>{" "}
                    <Typography variant="caption">{cpType}</Typography>
                  </Stack>
                  <Stack spacing={1}>
                    {servicesArr.map((s) => {
                      return this.renderServices(s);
                    })}
                  </Stack>
                </Stack>
              </Card>
            );
          })}
        </Stack>
      </Stack>
    );
  };

  // renderLabProviders = () => {
  //   let labPSLs = this.props.visit.labPSLs;
  //   if (!labPSLs.length) {
  //     return null;
  //   }
  //   return (
  //     <Stack spacing={2}>
  //       <Typography variant="subtitle2">Labs</Typography>
  //       {labPSLs.map((k, index) => {
  //         let pObj = k.providerObj;
  //         let pslName = k.pslName;
  //         let collectionAt = k.collectingClinicName || "Not Chosen";
  //         return (
  //           <Card
  //             key={index}
  //             sx={{
  //               p: 2,
  //               bgcolor: "bg.neutral",
  //               boxShadow: 2,
  //               maxWidth: 300,
  //             }}
  //           >
  //             <Stack spacing={1} direction="column" justifyContent="center">
  //               <Stack direction="row" alignItems="center" spacing={1}>
  //                 <Typography variant="overline">{k.providerName}</Typography>
  //               </Stack>
  //               <Stack direction="row" alignItems="center" spacing={1}>
  //                 {/* <Iconify icon="lets-icons:date-fill" /> */}
  //                 <Typography variant="overline">{pslName}</Typography>
  //               </Stack>
  //               <Stack direction="row" alignItems="center" spacing={1}>
  //                 <Iconify icon="lets-icons:date-fill" />
  //                 <Typography variant="caption">{collectionAt}</Typography>
  //               </Stack>
  //               <Stack direction="row" alignItems="center" spacing={1}>
  //                 <Iconify icon="mdi:location" />
  //                 <Typography variant="caption">
  //                   {pObj.providerAddress}, {pObj.providerCity},
  //                   {pObj.providerCountry}
  //                 </Typography>
  //               </Stack>
  //               <Stack direction="row" alignItems="center" spacing={1}>
  //                 <Iconify icon="material-symbols:call" />
  //                 <Typography variant="caption">
  //                   {pObj.providerPhone}
  //                 </Typography>
  //               </Stack>
  //             </Stack>
  //             {pObj.isFormFox && (
  //               <Chip
  //                 size="small"
  //                 color="info"
  //                 sx={{
  //                   marginTop: "0.5rem",
  //                   width: "fit-content",
  //                 }}
  //                 icon={<Iconify icon="material-symbols:star" />}
  //                 label="Booking through FormFox"
  //               />
  //             )}
  //           </Card>
  //         );
  //       })}
  //     </Stack>
  //   );
  // };
  renderValidations = () => {
    if (
      !["OUTREACH_FOR_SCHEDULING", "REQUESTED"].includes(
        this.props.visit.visitStatus
      )
    )
      return;

    let canScheduleStates = [
      "REQUESTED",
      "OUTREACH_FOR_SCHEDULING",
      "SCHEDULING_IN_PROGRESS",
      "RESCHEDULING_REQUESTED",
    ];
    let canSchedule = canScheduleStates.includes(this.state.visitStatus);

    let buttonElem = (
      <>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!!this.state.suppressEEAuthzEmail}
                onChange={(e) => {
                  this.setState(
                    {
                      suppressEEAuthzEmail: e.target.checked,
                    },
                    () => {
                      this.props.onSetVisitScalar(
                        "suppressEEAuthzEmail",
                        this.state.suppressEEAuthzEmail
                      );
                    }
                  );
                }}
              />
            }
            label="Suppress EE Authorization Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={!!this.state.suppressProviderAuthzEmail}
                onChange={(e) => {
                  this.setState(
                    {
                      suppressProviderAuthzEmail: e.target.checked,
                    },
                    () => {
                      this.props.onSetVisitScalar(
                        "suppressProviderAuthzEmail",
                        this.state.suppressProviderAuthzEmail
                      );
                    }
                  );
                }}
              />
            }
            label="Suppress Provider Authorization Email"
          />
        </FormGroup>
        <LoadingButton
          loading={this.state.isSaving}
          variant="contained"
          color="primary"
          onClick={() => this.pushToEventLog()}
          startIcon={<Iconify icon="fluent:book-add-20-filled" />}
        >
          Confirm Visit
        </LoadingButton>
        {/* <LoadingButton
          loading={this.state.isSaving}
          variant="contained"
          color="primary"
          onClick={async () => this.testFF()}
          startIcon={<Iconify icon="fluent:book-add-20-filled" />}
        >
          Test FF
        </LoadingButton> */}
      </>
    );

    if (!canSchedule) {
      buttonElem = null;
    }

    return (
      <Stack spacing={1}>
        <Stack spacing={1} direction="row" alignItems="center">
          {/*<Typography variant="subtitle1">Validations</Typography>
           <Stack
            spacing={1}
            direction="row"
            alignItems={"center"}
            flexWrap="wrap"
          >
            {showValidationsHere}
          </Stack> */}
        </Stack>
        <Stack width="100%" alignItems="end">
          {buttonElem}
        </Stack>
      </Stack>
    );
  };
  addNewEvent = async (payload) => {
    try {
      let res = await this.props.apiCallPost(
        "/visit/events/createNewVisitEvent",
        payload
      );
      this.setState({ isSaving: false });
      cogoToast.success("Event Logged");
    } catch (err) {
      cogoToast.error("Error Scheduling");
      console.log(err);
    }
  };

  formatWeeklyTimings = (weeklyTimings) => {
    // Initialize an object with all days of the week set to "No Schedule Specified"
    const formattedSchedule = {
      Monday: "No Schedule Specified",
      Tuesday: "No Schedule Specified",
      Wednesday: "No Schedule Specified",
      Thursday: "No Schedule Specified",
      Friday: "No Schedule Specified",
      Saturday: "No Schedule Specified",
      Sunday: "No Schedule Specified",
    };

    // Helper function to format the schedule string for a single entry
    function formatScheduleEntry(entry) {
      let parts = [];
      if (entry.isOpen24Hrs) {
        parts.push("Open 24 Hours");
      } else {
        parts.push(`${entry.startTime} to ${entry.endTime}`);
      }
      let flags = [];
      if (entry.apptOnly) flags.push("Appointment Only");
      if (entry.allowsWalkIn) flags.push("Allows Walk-Ins");
      if (entry.notes) flags.push(entry.notes);

      return (
        parts.join(", ") + (flags.length ? " (" + flags.join(", ") + ")" : "")
      );
    }

    weeklyTimings.forEach((entry) => {
      const day = entry.dayOfWeek;
      // Check for 24 hours open first
      if (entry.isOpen24Hrs) {
        formattedSchedule[day] = `Open 24 Hours${
          entry.apptOnly ? ", Appointment Only" : ""
        }${entry.allowsWalkIn ? ", Allows Walk-Ins" : ""}`;
      } else if (
        formattedSchedule[day] === "No Schedule Specified" ||
        !formattedSchedule[day].includes("Open 24 Hours")
      ) {
        // If the day already has a schedule, append; otherwise, replace "No Schedule Specified"
        const scheduleString = formatScheduleEntry(entry);
        formattedSchedule[day] =
          formattedSchedule[day] === "No Schedule Specified"
            ? scheduleString
            : formattedSchedule[day] + "; " + scheduleString;
      }
    });

    return formattedSchedule;
  };

  renderClinicTable = () => {
    const provider = this.props.visit.provider;
    const providerPSLs = this.props.visit.providerPSLsInfo;

    // const handleEmailAuthorizationLetter = async (psls) => {
    //   const providerName = psls[0].providerName;
    //   let payload = {
    //     visitId: this.props.visit._id,
    //     header: `Authorization letter emailed to ${providerName}`,
    //     notes: "",
    //     creator: "User",
    //   };
    //   const res = await this.sendAuthorizationLetterEmailForClinic(psls);
    //   if (res) {
    //     await this.addNewEvent(payload);
    //   }
    // };

    // const handleFaxAuthorizationLetter = async (psls) => {
    //   const providerName = psls[0].providerName;
    //   let payload = {
    //     visitId: this.props.visit._id,
    //     header: `Authorization letter faxed to ${providerName}`,
    //     notes: "",
    //     creator: "User",
    //   };
    //   const res = await this.sendAuthorizationLetterFaxForClinic(psls);
    //   if (res) {
    //     await this.addNewEvent(payload);
    //   }
    // };

    if (provider == null) {
      return (
        <Stack spacing={1} sx={{ mt: 2 }}>
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{ width: "100%" }}
          >
            <Typography variant="overline">No Clinic Selected</Typography>
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Stack
          direction="row"
          spacing={1}
          alignItems="center"
          sx={{ width: "100%" }}
        >
          <Typography variant="overline">Selected Clinic</Typography>
        </Stack>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Clinic Name</TableCell>
              <TableCell>Clinic Timings</TableCell>
              <TableCell>Booked Services</TableCell>
              <TableCell>Notes For Clinic</TableCell>
              <TableCell>Authorization</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell>
                <Stack spacing={0.5}>
                  <Typography variant="subtitle1">
                    {provider.providerName}
                  </Typography>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Button
                      href={`tel:${provider.providerPhone}`}
                      variant="outlined"
                      size="small"
                      startIcon={<Iconify icon="ic:baseline-phone" />}
                    >
                      {provider.providerPhone}
                    </Button>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Iconify icon="material-symbols:fax" />
                    <Typography variant="caption">
                      {provider.providerFax || ""}
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" spacing={0.5}>
                    <Iconify icon="ic:baseline-email" />
                    <Typography variant="caption">
                      {provider.providerEmail || "No email added for provider"}
                    </Typography>
                  </Stack>
                </Stack>
              </TableCell>
              <TableCell>
                {
                  <>
                    {Object.entries(
                      this.formatWeeklyTimings(provider.weeklyTimings)
                    ).map(([day, schedule], idx) => (
                      <Stack
                        key={idx}
                        direction="row"
                        spacing={1}
                        alignContent={"center"}
                      >
                        <Typography variant="caption">{day}</Typography>
                        <Typography variant="caption">{schedule}</Typography>
                      </Stack>
                    ))}
                  </>
                }
              </TableCell>
              <TableCell>
                <Stack direction="column" spacing={0.5}>
                  {providerPSLs.map((psl, pIdx) => (
                    <Chip
                      size="small"
                      sx={{
                        maxWidth: "500px",
                        height: "auto",
                        "& .MuiChip-label": {
                          display: "block",
                          whiteSpace: "normal",
                          wordBreak: "break-word",
                        },
                      }}
                      label={psl.providerServiceAlias}
                      variant="outlined"
                      key={pIdx}
                    />
                  ))}
                </Stack>
              </TableCell>
              <TableCell>
                {_.isString(this.props.visit.noteToClinic)
                  ? this.props.visit.noteToClinic
                  : _.get(
                      this.props.visit.noteToClinic,
                      provider._id,
                      "<No Notes Added>"
                    )}
              </TableCell>
              <TableCell>
                {["OUTREACH_FOR_SCHEDULING", "REQUESTED"].includes(
                  this.props.visit.visitStatus
                ) ? (
                  <Tooltip title="Preview Authorization Letter">
                    <LoadingButton
                      size="small"
                      startIcon={<Iconify icon="mdi:eye" />}
                      onClick={() => {
                        if (!this.props.visit.providerEncounterOnTime) {
                          cogoToast.error(
                            "Please select a date and time to schedule the visit for."
                          );
                          return;
                        }
                        window.open(
                          `/authorization-letter-clinic/${this.props.visit._id}`,
                          "_blank"
                        );
                      }}
                      variant="outlined"
                      loading={this.state.isDownloadingAuthLetter}
                    >
                      Preview
                    </LoadingButton>
                  </Tooltip>
                ) : (
                  <Stack
                    spacing={0.5}
                    direction="row"
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Tooltip title="Download Authorization Letter for Clinic">
                      <LoadingButton
                        size="small"
                        onClick={() => {
                          if (!this.props.visit.providerEncounterOnTime) {
                            cogoToast.error(
                              "Please select a date and time to schedule the visit for."
                            );
                            return;
                          }
                          window.open(
                            `/authorization-letter-clinic/${this.props.visit._id}`,
                            "_blank"
                          );
                        }}
                        variant="outlined"
                        loading={this.state.isDownloadingAuthLetter}
                      >
                        <Iconify icon="material-symbols:download" />
                      </LoadingButton>
                    </Tooltip>
                  </Stack>
                )}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Stack>
    );
  };

  renderERVisitScheduledModal = () => {
    return (
      <Dialog
        open={this.state.isERVisitScheduledModalOpen}
        onClose={() => {
          this.setState({ isERVisitScheduledModalOpen: false });
          this.props.onSetToScheduled();
        }}
      >
        <DialogContent>
          <Stack
            justifyContent="center"
            spacing={2}
            sx={{ p: 1, mt: 2, textAlign: "center" }}
          >
            <AnimatedCheck />
            <Typography variant="h6">Confirmed!</Typography>
            <DialogContentText>
              Visit has been scheduled. Next Steps are:
            </DialogContentText>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => {
              this.props.onSetToScheduled();
              this.setState({ isERVisitScheduledModalOpen: false });
            }}
          >
            Close
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open("/visits", "_self");
            }}
          >
            Goto Visit Dashboard
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  render() {
    if (this.state.isLoading) {
      return <LinearProgress />;
    }
    if (this.state.isError) {
      return <View500 />;
    }
    return (
      <Paper
        sx={{
          p: 2,
          bgcolor: "bg.neutral",
          boxShadow: 2,
        }}
      >
        <Stack spacing={2}>
          {this.renderEmployeeInformation()}
          {this.renderClinicTable()}
          {this.renderValidations()}
        </Stack>
      </Paper>
    );
  }
}

export default WithAPICall(VisitReviewSummarySchedule);
