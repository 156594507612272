import React, { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { Box } from "@mui/system";
import { format } from "date-fns";
import _ from "lodash";
import { useProtectedApi } from "@/hooks/use-apiCall";

const OHM_typeOfClearance = {
  Cleared: "Qualified",
  "Cleared With Restriction": "Qualified - With Limitations",
  "Not Cleared": "Not Qualified",
};

const FormContainer = styled("div")({
  width: "210mm",
  minHeight: "297mm",
  padding: "20mm",
  margin: "auto",
  fontFamily: "Arial, sans-serif",
  fontSize: "10pt",
  lineHeight: "1.2",
  backgroundColor: "#fff",
  boxShadow: "0 0 10px rgba(0,0,0,0.1)",
  display: "flex",
  flexDirection: "column",
});

const Header = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flex-start",
  marginBottom: "10mm",
});

const LogoContainer = styled("div")({
  flex: 1,
});

const TitleContainer = styled("div")({
  flex: 1,
  textAlign: "center",
});

const Title = styled("h1")({
  fontSize: "14pt",
  fontWeight: "bold",
  margin: "0 0 5px 0",
});

const Subtitle = styled("h2")({
  fontSize: "10pt",
  fontWeight: "normal",
  margin: "0",
});

const Table = styled("table")({
  width: "100%",
  borderCollapse: "collapse",
  marginBottom: "5mm",
});

const Td = styled("td")({
  padding: "1mm 2mm",
  verticalAlign: "top",
  fontSize: "10pt",
});

const SectionTitle = styled("div")({
  fontWeight: "bold",
  marginBottom: "2mm",
  fontSize: "10pt",
});

const CheckboxContainer = styled("div")({
  display: "flex",
  justifyContent: "flex-end",
  gap: "10mm",
  marginTop: "2mm",
});

const Checkbox = styled("div")({
  width: "4mm",
  height: "4mm",
  border: "1px solid black",
  display: "inline-block",
  marginRight: "2mm",
});

const SmallText = styled("div")({
  fontSize: "8pt",
  marginTop: "4mm",
  marginBottom: "4mm",
});

const Signature = styled("div")({
  borderTop: "1px solid black",
  marginTop: "10mm",
  paddingTop: "2mm",
});
const InfoSection = styled("div")({
  display: "flex",
  flexDirection: "column",
  gap: "4mm",
  marginBottom: "10mm",
});

const InfoRow = styled("div")({
  display: "flex",
  justifyContent: "space-between",
});

const InfoItem = styled("div")({
  flex: 1,
});

const Label = styled("span")({
  fontWeight: "bold",
  marginRight: "2mm",
});

// const formatDate = (dateInput) => {
//   if (!dateInput) return "";
//   const date = new Date(dateInput);
//   return isNaN(date.getTime()) ? "" : format(date, "MM/dd/yyyy");
// };

// const renderValue = (value) => {
//   if (value === undefined || value === null) return "";
//   if (typeof value === "object") return JSON.stringify(value);
//   return value.toString();
// };

const OHM = ({ id }) => {
  const { callApi } = useProtectedApi();
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getVisitData = async () => {
    try {
      let res = await callApi("/print/clearance/payload", {
        visitId: id.split("_")[0],
      });
      setData(res);
      setIsLoading(false);
    } catch (err) {
      setIsError(true);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getVisitData();
  }, [id]);

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error loading report data</div>;
  if (!data) return <div>No data available</div>;

  const p = () => {
    if (isLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    if (!data) {
      return null;
    }
    const protocolId = id.split("_")[1];
    let bp = data.protocolsInfo;
    let p = _.find(bp, { _id: protocolId });
    return p;
  };

  const ps = () => {
    if (isLoading) {
      return null;
    }
    if (isError) {
      return null;
    }
    if (!data) {
      return null;
    }
    const protocolId = id.split("_")[1];
    let bp = data.protocolsInfo;
    let p = _.find(bp, { _id: protocolId });
    let ps = data.protocolClearance;
    let protocolSummaryObj = ps[protocolId];
    return protocolSummaryObj;
  };

  return (
    <FormContainer>
      <Box
        component="img"
        src="/logo/logo_full.svg"
        sx={{ width: 250, height: 70 }}
      />

      <TitleContainer>
        <Title>WORK STATUS REPORT</Title>
        <Subtitle>Employer Copy</Subtitle>
      </TitleContainer>

      <InfoSection>
        <InfoRow>
          <InfoItem>
            <Label>TYPE OF EXAMINATION:</Label>
            {p().companyProtocolName}
          </InfoItem>
          <InfoItem>
            <Label>COMPANY:</Label>
            {data.company.companyName}
          </InfoItem>
        </InfoRow>
        <InfoRow>
          {/* <InfoItem>
            <Label>EXAM CLASSIFICATION:</Label>
            Post Offer Examination
          </InfoItem> */}
          <InfoItem>
            <Label>POSITION:</Label>
            {data.employee.employeeJobTitle}
          </InfoItem>
        </InfoRow>
        <InfoRow>
          <InfoItem>
            <Label>EMPLOYEE:</Label>
            {data.employee.employeeName}
          </InfoItem>
          <InfoItem>
            <Label>LOCATION:</Label>
            {data.employee.employeeJobLocation}
          </InfoItem>
        </InfoRow>
        <InfoRow>
          <InfoItem>
            <Label>ID:</Label>
            {data.employee.employeeNumber}
          </InfoItem>
          {/* <InfoItem>
            <Label>SITE:</Label>
          </InfoItem> */}
        </InfoRow>
        <InfoRow>
          <InfoItem>
            <Label>DATE OF EXAM:</Label>
            {ps().clearedAt ? format(ps().clearedAt, "MM/dd/yyyy") : ""}
          </InfoItem>
        </InfoRow>
        <InfoRow>
          <InfoItem>
            <Label>EXPIRATION DATE: </Label>
          </InfoItem>
        </InfoRow>
      </InfoSection>

      <SmallText>
        The following recommendations are based on a review of one or all of the
        following: a base history questionnaire, supporting diagnostic tests,
        physical examination, and the essential functions of the position
        applied for or occupied by the individual named above.
      </SmallText>

      {/* <div>
        Has the employee any limitations in accordance with 29 CFR §1910.134
        (Respirator)?
        <CheckboxContainer>
          <div>
            <Checkbox /> Yes
          </div>
          <div>
            <Checkbox
              style={{
                backgroundColor:
                  data.hasRespiratoryLimitations === false
                    ? "black"
                    : "transparent",
              }}
            />{" "}
            No
          </div>
        </CheckboxContainer>
      </div> */}

      <SectionTitle style={{ marginTop: "5mm" }}>Status</SectionTitle>
      <div>
        <Checkbox
          style={{
            backgroundColor:
              OHM_typeOfClearance[ps().typeOfClearance] === "Qualified"
                ? "black"
                : "transparent",
          }}
        />
        <strong>QUALIFIED</strong> The examination indicates no significant
        medical condition. Employee can be assigned any work consistent with
        skills and training.
      </div>
      <div>
        <Checkbox
          style={{
            backgroundColor:
              OHM_typeOfClearance[ps().typeOfClearance] ===
              "Qualified - With Limitations"
                ? "black"
                : "transparent",
          }}
        />
        <strong>QUALIFIED - WITH LIMITATIONS</strong> The examination indicates
        that a medical condition currently exists that limits work assignments
        on the following basis:
      </div>
      <div>
        <Checkbox
          style={{
            backgroundColor:
              OHM_typeOfClearance[ps().typeOfClearance] === "Not Qualified"
                ? "black"
                : "transparent",
          }}
        />
        <strong>NOT QUALIFIED</strong>
      </div>
      <div>
        <Checkbox
          style={{
            backgroundColor:
              OHM_typeOfClearance[ps().typeOfClearance] === "Deferred"
                ? "black"
                : "transparent",
          }}
        />
        <strong>DEFERRED</strong> The examination indicated that additional
        information is necessary. The employee has been given the following
        instructions.
      </div>

      <div style={{ marginTop: "5mm" }}>
        <strong>Comments:</strong>
        <p>{ps().finalClearanceText}</p>
      </div>

      <SmallText>
        I have reviewed the medical data of the above named employee, and
        informed the employee of the results of the medical examination and any
        medical conditions that require follow-up examination or treatment.
      </SmallText>

      <div style={{ marginTop: "5mm" }}>
        <div>Name of Physician: {data.assignedMD.name}</div>
        <Signature>
          Signature:{" "}
          <Box
            component="img"
            src={data.assignedMD.signatureImagePsUrl}
            sx={{ width: 80, height: "auto", cursor: "pointer" }}
          />
        </Signature>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "2mm",
          }}
        >
          Date: {ps().clearedAt ? format(ps().clearedAt, "MM/dd/yyyy") : ""}
        </div>
      </div>

      <SmallText style={{ marginTop: "20mm", textAlign: "center" }}>
        300 S. Harbor Blvd., Ste. 600, Anaheim, CA 92805
        <br />
        (800) 455-6155 • www.workcare.com • info@workcare.com
      </SmallText>
    </FormContainer>
  );
};

export default OHM;
