import React from "react";
import {
  apiCallPostUnuath,
  niceDate,
  niceDateTime,
  niceDateTZ,
  niceTime,
} from "../utils/fn";
import {
  Button,
  List,
  ListItem,
  Stack,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { undoFFSampleType } from "src/assets/data/formfox";
import _ from "lodash";
import { formattedTZTime } from "../utils/fn";
import { format } from "date-fns";
import { parseISO } from "date-fns";
import { ReactBarcode } from "react-jsbarcode";
class AuthorizationLetter extends React.Component {
  state = {
    isLoading: true,
    visitId: null,
    data: null,
    isError: false,
  };

  componentDidMount() {
    this.handlePrint();
    this.getVisitData();
  }
  getVisitData = async () => {
    try {
      let res = await apiCallPostUnuath("/visit/getVisitByIDForPrint", {
        visitId: this.props.id,
      });
      const employerDER = await apiCallPostUnuath("/company/getDERForVisit", {
        visitId: this.props.id,
      });

      const hasDrugPanel = _.some(res.servicesInfo, function (s) {
        return (
          s.serviceType === "DRUG" && s.serviceRequiresProviderType === "Lab"
        );
      });

      const isViaFF = _.some(res.servicesInfo, function (s) {
        return res.serviceBookingInfo[s._id]?.bookingChannel === "FORMFOX";
      });

      // Pull Auth specific information HERE.
      const p0 = res.protocolsInfo[0];
      const reasonForTestFF =
        p0?.formFoxReasonForTest?.name || "<No Reason for TestProvided>";
      const isDOT = p0?.isDot;
      const DOTAgency = p0?.regulatoryAuthority;

      this.setState({
        data: res,
        hasDrugPanel: hasDrugPanel,
        employerDER: employerDER,
        reasonForTestFF: reasonForTestFF,
        isDOT: isDOT,
        DOTAgency: DOTAgency,
        isLoading: false,
        isViaFF: isViaFF,
      });
    } catch (err) {
      this.setState({
        isError: true,
        isLoading: false,
      });
      console.log(err);
    }
  };

  handlePrint = () => {
    const style = document.createElement("style");
    style.innerHTML = `
        @media print {
          body * {
            visibility: hidden;
          }
          #printableArea, #printableArea * {
            visibility: visible;
          }
        }
      `;
    document.head.appendChild(style);
    // window.print();
    // document.head.removeChild(style);
  };

  lookupPanelID = {
    CRL: "crlPanelCode",
    LabCorp: "labCorpPanelCode",
    Quest: "questPanelCode",
    Pactox: "pactoxPanelCode",
    NationalJewish: "njPanelCode",
  };

  lookupAccountNumber = {
    CRL: { true: "crlDotAccountNumber", false: "crlNonDotAccountNumber" },
    LabCorp: {
      true: "labCorpDotAccountNumber",
      false: "labCorpNonDotAccountNumber",
    },
  };

  renderMedicalRelease = () => {
    const employeeName = this.state.data?.employee?.employeeName;
    const companyName = this.state.data?.company?.companyName;
    return (
      <Box mt={1}>
        <Typography variant="subtitle1" gutterBottom>
          AUTHORIZATION FOR RELEASE OF INFORMATION
        </Typography>
        <Typography variant="body2" paragraph>
          I, {employeeName}, hereby authorize the physician and clinicians
          performing examination(s), screening(s), drug and alcohol testing
          and/or evaluation in connection with my fitness for duty examination
          to disclose my individually identifiable health information, as
          described below, to WorkCare, {companyName} Medical Team, and to{" "}
          {companyName} management and/or EAP, as applicable.
        </Typography>
        <Typography variant="body2" paragraph>
          I further authorize Workcare to disclose my individually identifiable
          health information obtained because of any examination(s),
          screening(s), drug and alcohol testing and/or evaluation, to{" "}
          {companyName} Medical Team, and to {companyName} management and/or
          EAP, as applicable.
        </Typography>
        <Typography variant="body2" paragraph>
          <b>
            Specific description of information to be used or disclosed
            (including dates):
          </b>
          I understand that by signing this form I am authorizing the release of
          any pertinent information related to my fitness for duty examination,
          including but not limited to medical, psychiatric and/or psychological
          reports and conclusions. This information may also include drug and
          alcohol screen results and mental health and substance abuse treatment
          information.
        </Typography>
        <Typography variant="body2" paragraph>
          <b>Specific purposes of the disclosure:</b> I understand that the
          purpose of the disclosure of this information is to determine my
          fitness for duty and/or ability to return to work, including the
          identification and evaluation of necessary accommodation(s), if any.
        </Typography>
        <Typography variant="body2" paragraph>
          <b>Specific purposes of the disclosure:</b> I understand that the
          purpose of the disclosure of this information is to determine my
          fitness for duty and/or ability to return to work, including the
          identification and evaluation of necessary accommodation(s), if any.
        </Typography>
        <Typography variant="body2" paragraph>
          <b>
            I have read and understood the following statements about my rights:
          </b>
        </Typography>
        <List dense disablePadding>
          <ListItem
            sx={{ display: "list-item", listStyleType: "disc", pl: 0, ml: 2 }}
          >
            The purpose of this examination or screening is to provide
            information to {companyName} about my ability to work in the the
            position for which I am employed or the position for which I am
            being considered for employment.
          </ListItem>
          <ListItem
            sx={{ display: "list-item", listStyleType: "disc", pl: 0, ml: 2 }}
          >
            Because the sole purpose of my examination, assessment or screening
            is to create health information for disclosure to
            {companyName}, I understand the medical professional(s) and/or
            entity(ies) may decline to perform my examination or screening if I
            do not sign this Authorization.
          </ListItem>
          <ListItem
            sx={{ display: "list-item", listStyleType: "disc", pl: 0, ml: 2 }}
          >
            I understand that the medical professional(s) and/or entity(ies)
            that will perform my examination or screening are not providing
            medical care. This examination is not intended as treatment or to
            obtain information for my use. All records resulting from this
            examination or screening shall be the property of {companyName}.
          </ListItem>
          <ListItem
            sx={{ display: "list-item", listStyleType: "disc", pl: 0, ml: 2 }}
          >
            I may revoke this Authorization at any time. This must be done, in
            writing, by notifying the medical professional(s) and/or entity(ies)
            providing the examination, screening or evaluation, as identified
            above. A copy of the revocation must also be provided to WorkCare at
            medical.records@workcare.com. My revocation will be effective upon
            receipt by the medical professional(s) and/or entity(ies) but will
            not affect actions previously taken in reliance on this
            Authorization. If not revoked, this Authorization will be effective
            for one year from the date it is signed.
          </ListItem>
          <ListItem
            sx={{ display: "list-item", listStyleType: "disc", pl: 0, ml: 2 }}
          >
            The information disclosed pursuant to this Authorization may be
            re-disclosed by the recipient (without my authorization) and may no
            longer be protected by federal law.
          </ListItem>
          <ListItem
            sx={{ display: "list-item", listStyleType: "disc", pl: 0, ml: 2 }}
          >
            I am entitled to receive a copy of this signed Authorization upon
            request and may access WorkCare' s privacy practices at
            www.workcare.com.
          </ListItem>
        </List>
        <br />
        <Typography variant="body2" paragraph>
          Employee Signature: ______________________________
        </Typography>
        <Typography variant="body2" paragraph>
          Signature Date: __________________________________
        </Typography>
      </Box>
    );
  };

  renderServiceLine = (svc, index) => {
    let serviceText = svc.serviceName;
    let needsObservation = svc.formFoxSampleRequestObservation;
    let serviceUnderline = "";
    let serviceUnderline2 = "";

    const serviceProtocol = this.state.data.serviceParentProtocols[svc._id];
    const pN = this.state.data.protocolsInfo.find(
      (p) => p._id === serviceProtocol
    );

    const svcMetadataIndex = pN?.masterServices?.findIndex(
      (ms) => ms === svc._id
    );
    const svcMetadata = pN?.serviceMetadata?.[svcMetadataIndex];

    const reasonForTestFF =
      pN?.formFoxReasonForTest?.name || "<No Reason for TestProvided>";

    let isDOT = null;
    if (pN?.isDot) {
      if (svcMetadata?.serviceIsNonDOT) {
        isDOT = false;
      } else {
        isDOT = true;
      }
    } else {
      isDOT = false;
    }

    const DOTAgency = pN?.regulatoryAuthority;

    if (svc.serviceRequiresProviderType === "Lab") {
      // destination lab;
      const destinationLab =
        this.state.data.serviceBookingInfo[svc._id]["destinationLab"] || "CRL";
      // Panel ID: (This comes from the service screen, formfox tab, panel code)
      const panelIDKey = this.lookupPanelID[destinationLab];
      const panelID = svc[panelIDKey];
      // Account Number:(This is found on the employer screen, on the formfox page)
      const accountNumberKey = this.lookupAccountNumber[destinationLab][isDOT];
      const accountNumber = this.state.data.company[accountNumberKey];

      needsObservation = pN?.requestObservation;
      // If it is DOT we need Agency: (The DOT Agency should only show on the clearance if in Navigator
      // under the protocol where it says is this a DOT protocol is enabled and If the “Is the a DOT protocol” is
      // enabled the agency should pull from the regulatory authority such as FMCSA.)
      serviceText = `${undoFFSampleType(svc.formFoxSampleType)} Collection`;
      serviceUnderline = `${destinationLab} Panel Code: ${panelID || "<No Panel ID on file>"} for Account: ${accountNumber || "<No Account Number on File>"}`;
      if (isDOT) {
        serviceUnderline = `${serviceUnderline} [Agency: ${DOTAgency}]`;
      } else {
        serviceUnderline = `${serviceUnderline} (Non-DOT)`;
      }
      serviceUnderline = `${serviceUnderline} [Reason for Test: ${reasonForTestFF}]`;
      if (needsObservation) {
        serviceUnderline = `${serviceUnderline} [Requires Observation]`;
      }
    }
    if (
      this.state.data.serviceBookingInfo[svc._id]["bookingChannel"] ===
      "FORMFOX"
    ) {
      const refTestId =
        this.state.data.formFoxOrderData?.ReferenceTestID?.[0] || "<TBD>";
      serviceUnderline2 = `This service is fulfilled via the FormFox Marketplace via Reference Test ID: ${refTestId}.`;
    }
    return (
      <div>
        <Typography variant="body2">
          {index + 1}. {serviceText}
        </Typography>
        {serviceUnderline && (
          <Typography variant="body2" color="text.secondary" marginLeft={1}>
            {serviceUnderline}
          </Typography>
        )}
        {serviceUnderline2 && (
          <Typography variant="body2" color="text.secondary" marginLeft={1}>
            {serviceUnderline2}
          </Typography>
        )}
      </div>
    );
  };

  renderDrugCollectionInfo() {
    let nDrugServices = 0;
    for (const svc of this.state.data?.servicesInfo || []) {
      if (
        this.state.data.serviceBookingInfo[svc._id] != null &&
        svc.serviceType === "DRUG"
      ) {
        nDrugServices++;
      }
    }
    if (nDrugServices < 2) {
      return null;
    }
    return (
      <Box mt={1}>
        <Typography variant="body2">
          This exam contains {nDrugServices} drug screen(s).
        </Typography>
        <Typography variant="caption">
          Please ensure that sample collections for all drug screens are
          completed.
        </Typography>
      </Box>
    );
  }

  renderDERInfo = () => {
    if (!this.state.hasDrugPanel) {
      return null;
    }
    return (
      <>
        <Box mt={1}>
          <Typography variant="subtitle1" gutterBottom>
            DER Information:
          </Typography>
          <Typography variant="body2">
            Name:{" "}
            <b>{this.state.employerDER?.contactName || "<No DER on file>"}</b>
          </Typography>
          <Typography variant="body2">
            Phone:{" "}
            <b>{this.state.employerDER?.officePhone || "<No phone on file>"}</b>
          </Typography>
          <Typography variant="body2">
            Fax: <b>{this.state.employerDER?.fax || "<No fax on file>"}</b>
          </Typography>
        </Box>
        <Box mt={1}>
          <Typography variant="subtitle1" gutterBottom>
            MRO:
          </Typography>
          <Typography variant="body2">
            Name: <b>Sarah Johnson, MD</b>
          </Typography>
          <Typography variant="body2">
            Phone: <b>800-350-4511</b>
          </Typography>
          <Typography variant="body2">
            Fax: <b>339-645-2524</b>
          </Typography>
        </Box>
      </>
    );
  };

  render() {
    if (this.state.data == null) {
      return null;
    }
    let CLINIC_INSN = [
      "Contact WorkCare if any of the authorized services cannot be performed. WorkCare can be reached at 1-800-350-4511 option 5.",
      "Please check and confirm all identification prior to examination.",
      "Please have the employee sign the Medical Information Release and return with the completed paperwork.",
      "Any treatment outside of what is authorized on this protocol must be approved by WorkCare.",
      <Typography variant="body2">
        Please fax a copy of the medical documentation to 714-426-8171 or email
        it to{" "}
        <a href="mailto:documents@workcare.com" style={{ color: "blue" }}>
          documents@workcare.com{" "}
        </a>{" "}
        the day of the visit.
      </Typography>,
      "For all B-read x-rays please mail a copy of the chest x-ray to 600 S. Harbor Blvd. Suite 600, Anaheim, CA 92805",
      "All services declined by the employee needs to be documented and submitted with the completed paperwork.",
    ];
    if (this.state.data?.formFoxOrderData?.ffBarcode) {
      CLINIC_INSN.push(
        "This authorization includes services that are fulfilled via the FormFox Marketplace. Please use the barcode on the top-right to authorize these services."
      );
    }
    return (
      <Paper
        elevation={3}
        sx={{
          width: "210mm",
          minHeight: "200mm",
          padding: "10mm",
          margin: "auto",
          boxSizing: "border-box",
          position: "relative",
          "@media print": {
            boxShadow: "none",
            margin: 0,
          },
        }}
      >
        {/* <Button
          variant="contained"
          onClick={handlePrint}
          sx={{
            position: "absolute",
            top: "10mm",
            right: "10mm",
            "@media print": {
              display: "none",
            },
          }}
        >
          Print
        </Button> */}

        <div id="printableArea">
          <Stack
            direction="row"
            alignItems="center"
            spacing={1}
            justifyContent={"space-between"}
          >
            <Box
              component="img"
              sx={{
                width: "auto",
                maxWidth: { xs: 350, md: 250 },
              }}
              alt="Company Logo"
              src="/logo/crop_logo_full.png"
            />
            {this.state.isViaFF && (
              <Stack direction="column">
                {/* <img
                  style={{ width: "100px", height: "auto" }}
                  alt="FormFox Authorization Barcode"
                  src={this.state.data?.formFoxOrderData?.ffBarcode}
                /> */}
                <div
                  style={{
                    width: "100px",
                    height: "80px",
                  }}
                >
                  <ReactBarcode
                    value={
                      this.state.data?.formFoxOrderData?.ReferenceTestID?.[0]
                    }
                    renderer="image"
                  />
                </div>
                <img
                  style={{ width: "auto", height: "15px" }}
                  alt="FormFox Logo"
                  src={"/fflogo.jpeg"}
                />
                {/* <Typography
                  variant="body2"
                  color="text.secondary"
                  marginLeft={1}
                  style={{ breakWords: "break-all" }}
                >
                  Please this barcode to fulfill the services for FormFox
                  Marketplace.
                </Typography> */}
              </Stack>
            )}
          </Stack>
          <Typography variant="h5" align="center" gutterBottom>
            Service Authorization Form
          </Typography>

          <Typography variant="body2" paragraph>
            Workcare has scheduled an employee at your clinic,{" "}
            <b>{this.state.data?.provider?.providerName}</b> on{" "}
            {/* <b>
              {formattedTZTime(this.state.data?.providerEncounterOnDate, "PST")}
            </b> */}
            <b>
              {niceDateTZ(
                this.state.data?.providerEncounterOnDate?.replaceAll("Z", "")
              )}
            </b>
            {/* <b>{niceDate(this.state.data?.providerEncounterOnDate)}</b> at{" "}
            <b>{niceTime(this.state.data?.providerEncounterOnTime)}</b>. */}
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Clinic Information:
          </Typography>
          <Typography variant="body2">
            Clinic Name: <b>{this.state.data?.provider.providerName}</b>
            <br />
            Address:{" "}
            <b>
              {this.state.data?.provider.providerAddress}{" "}
              {this.state.data?.provider.providerCity}{" "}
              {this.state.data?.provider.providerState}{" "}
              {this.state.data?.provider.providerPostalCode}
            </b>
            <br />
            Phone:{" "}
            <b>
              {this.state.data?.provider.providerPhone ||
                "<No Phone Number on record>"}
            </b>
          </Typography>
          <br />
          <Typography variant="subtitle1" gutterBottom>
            Employee Information:
          </Typography>
          <Typography variant="body2">
            WorkCare Client Name: <b>{this.state.data?.company.companyName}</b>
            <br />
            Employee Name: <b>{this.state.data?.employee.employeeName}</b>
            <br />
            Employee Date of Birth:{" "}
            <b>
              {niceDateTZ(
                this.state.data?.employee.employeeDoB?.replaceAll("Z", "")
              )}
            </b>
            <br />
            Employee Number: <b>{this.state.data?.employee.employeeNumber}</b>
          </Typography>

          <Box mt={1}>
            <Typography variant="subtitle1" gutterBottom>
              Authorized Services:
            </Typography>
            <List disablePadding component="ol">
              {this.state.data?.servicesInfo.map((service, index) => {
                if (this.state.data.serviceBookingInfo[service._id] == null) {
                  // if we selected 4 services and clinic has 3
                  // and we are in the preview.
                  return null;
                }
                return (
                  <ListItem key={index} disablePadding>
                    <ListItemText
                      primary={this.renderServiceLine(service, index)}
                    />
                  </ListItem>
                );
              })}
            </List>
          </Box>

          {this.renderDrugCollectionInfo()}

          {this.renderDERInfo()}

          <Box mt={1}>
            <Typography variant="subtitle1" gutterBottom>
              Additional Information:
            </Typography>
            <Typography variant="body2">
              {this.state.data?.noteToClinic || "<No Notes Provided>"}
            </Typography>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1" gutterBottom>
              Clinic Instructions:
            </Typography>
            <List dense disablePadding>
              {CLINIC_INSN.map((instruction, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "list-item",
                    listStyleType: "disc",
                    pl: 0,
                    ml: 2,
                  }}
                >
                  <ListItemText
                    primary={
                      <Typography variant="body2">{instruction}</Typography>
                    }
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1" gutterBottom>
              Lab Specimen Handling:
            </Typography>
            <Typography variant="body2" paragraph>
              The College of American Pathologist (CAP), our laboratory
              accrediting agency, requires changes regarding proper specimen
              labeling to improve patient safety. Below is an excerpt from the
              CAP Laboratory General Checklist:
            </Typography>
            <List dense disablePadding>
              {[
                "All Primary specimen containers are labeled with at least 2 patient-specific identifiers.",
                "The identifiers must correspond to information on this appointment protocol.",
                "Mark Friday appointments for Saturday delivery.",
              ].map((item, index) => (
                <ListItem
                  key={index}
                  sx={{
                    display: "list-item",
                    listStyleType: "disc",
                    pl: 0,
                    ml: 2,
                  }}
                >
                  <ListItemText
                    primary={<Typography variant="body2">{item}</Typography>}
                  />
                </ListItem>
              ))}
            </List>
          </Box>

          <Box mt={1}>
            <Typography variant="subtitle1" gutterBottom>
              Billing:
            </Typography>
            <Typography variant="body2" paragraph>
              WorkCare is responsible for payment of these services and should
              be invoiced via email to
              <a
                href="mailto:clinic.invoices@workcare.com"
                style={{ color: "blue" }}
              >
                {" "}
                clinic.invoices@workcare.com.
              </a>
            </Typography>
          </Box>

          <Typography variant="body2" fontWeight="bold">
            Please contact WorkCare at 1-800-350-4511 option 5, with any
            questions or concerns. Do not reach out to the WorkCare client
            except for the WorkCare Client designated employer representative
            (DER) regarding drug and alcohol matters.
          </Typography>

          <div
            style={{
              pageBreakBefore: "always",
            }}
          >
            {this.renderMedicalRelease()}
          </div>
        </div>
      </Paper>
    );
  }
}

export default AuthorizationLetter;
