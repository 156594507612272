import { Helmet } from "react-helmet-async";
import { useParams } from "src/routes/hooks";

import FormPreEmploymentForArkema from "./FormPreEmploymentForArkema";

export default function FormPreEmploymentForArkemaByID() {
  const params = useParams();
  const { id } = params;
  return (
    <>
      <Helmet>
        <title> Visit Protocol Clearance</title>
      </Helmet>
      <FormPreEmploymentForArkema id={id} />
    </>
  );
}
